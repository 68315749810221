import Person from "components/Person/Person";
import { PersonContainerProp } from "interfaces/herosection.interface";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

export const PersonContainer = ({
    timerQueue,
    dequeue
}: PersonContainerProp) => {
    const stickManOut = window.innerWidth < 1280 ? "-15rem": "-25rem";
    return (
        <div tw="absolute top-[12rem] left-[20rem]">
            {timerQueue.map(({ id, person }) => (
                <div
                    key={id}
                    css={[
                        tw`absolute top-[1rem] md:hidden sm:hidden`,
                        {
                            right: person.isEscaping ? stickManOut : "auto",
                            left: person.isEscaping ? "auto" : "-23vw"
                        }
                    ]}
                >
                    <Person personProp={person} dequeue={dequeue} />
                </div>
            ))}
        </div>
    );
};
