import { Link } from "react-router-dom";
import { t } from "i18next";
import logo from "assets/images/logo.svg";
import Burger from "components/Header/Burger";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const InfoHeader = () => (
    <>
        <header tw="flex flex-row justify-center bg-white fixed top-0 left-0 right-0 z-[13] pt-[4vh] px-4 pb-8">
            <div tw="flex flex-row w-[50%] sm:w-[100%] mt-3 sm:mx-2">
                <img
                    src={logo}
                    alt="Menu"
                    tw="h-10 w-10 mr-8 sm:mr-4 align-bottom"
                />
                <Link to={`/`}>
                    <p tw="flex flex-row tracking-widest font-thin text-black align-bottom mt-2 font-workSans uppercase">
                        <span tw="whitespace-pre"> </span>
                        {"<"}
                        <span tw="whitespace-pre"> </span>
                        {t("back_to_the_clock")}
                    </p>
                </Link>
                <div tw="ml-auto">
                    <Burger />
                </div>
            </div>
        </header>
    </>
);

export default InfoHeader;
