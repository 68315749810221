import { ALLOPTION } from "./Groups";

export const countryOptions = [
    { value: ALLOPTION, label: "All Countries" },
    { value: "DEU", label: "Germany" },
    { value: "AUT", label: "Austria" },
    { value: "IRL", label: "Ireland" },
    { value: "KEN", label: "Kenya" },
    { value: "BRA", label: "Brazil" },
    { value: "USA", label: "USA" },
    { value: "CAN", label: "Canada" },
    { value: "POL", label: "Poland" },
    { value: "GBR", label: "Great Britain" }
];

export const countryFullName: { [key: string]: string } = {
    all: "All Countries",
    DEU: "Germany",
    AUT: "Austria",
    IRL: "Ireland",
    KEN: "Kenya",
    BRA: "Brazil",
    USA: "USA",
    CAN: "Canada",
    POL: "Poland",
    GBR: "Great Britain"
};

export const hasSubnational: string[] = ["KEN"];
