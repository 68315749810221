import { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import { GeoJSON, MapContainer, ZoomControl } from "react-leaflet";
import { Feature, FeatureCollection } from "geojson";
import { Layer, LeafletEvent } from "leaflet";
import { useWorldPoverty } from "context/worldPoverty";
import FullCountryCard from "components/Map/FullCountryCard";
import CountryCard from "components/Map/CountryCard";
import Loader from "components/Loader";
import useLeaflet from "hooks/useLeaflet";
import { FeatureShape } from "interfaces/feature-shape.interface";
import { CountryDataShape } from "interfaces/world-poverty-context.interface";
import { centerMap } from "utils/Leaflet";
import { regionStyle } from "utils/Map";
import { NONEOPTION, ALLOPTION } from "utils/Groups";
/** @jsxImportSource @emotion/react */
import tw, { theme as twTheme } from "twin.macro";

const Map = () => {
    const {
        mapView,
        continent,
        country,
        countryData,
        county,
        main_query_data,
        daily_spending,
        ageGender,
        setActiveFilters
    } = useWorldPoverty();
    const [tooltipInfo, setTooltipInfo] = useState<FeatureShape | null>(null);
    const [mapRef, setMapRef] = useState<LeafletEvent | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const geoJsonLayer = useRef<any>(null);
    const { handleResize } = useLeaflet(mapRef);
    const countyRef = useRef<string>("");
    const countryRef = useRef<string>("");
    const continentRef = useRef<string>("");
    const fullCountryRef = useRef<boolean>(false);

    useEffect(() => {
        countyRef.current = county;
        countryRef.current = country;
        continentRef.current = continent;

        if (geoJsonLayer.current) {
            if (countryData && county !== NONEOPTION) {
                geoJsonLayer.current
                    .clearLayers()
                    .addData(countryData.countyValues);
            } else {
                geoJsonLayer.current
                    .clearLayers()
                    .addData(main_query_data?.countryValues);
            }
        }
    }, [
        main_query_data,
        county,
        country,
        continent,
        countryData,
        daily_spending,
        ageGender
    ]);


    const highlightFeature = (layer: FeatureShape) => {
        setTooltipInfo(layer);
    };

    const resetHighlight = () => {
        setTooltipInfo(null);
    };

    const clickedCountry = (clickedCountry: FeatureShape) => {
        setActiveFilters({
            name: countyRef.current !== NONEOPTION ? "county" : "country",
            value: clickedCountry.target.feature.id
        });
        zoomToCountry(clickedCountry.target.feature.id);
    };

    const zoomToCountry = (clickedCountry: string) => {
        const selectedCountry: any = Object.values(
            geoJsonLayer.current._layers
        ).find(({ feature }: any) => feature.id === clickedCountry);

        setTimeout(() => {
            mapRef?.target.invalidateSize();
            mapRef?.target.flyToBounds(selectedCountry.getBounds(), {
                paddingBottomRight: [100, 0]
            });

            selectedCountry.removeFrom(geoJsonLayer.current);
            selectedCountry.addTo(geoJsonLayer.current);
        }, 500);
    };

    const shiftCountry = (isExpanded: boolean) => {
        const selectedCountry: any = Object.values(
            geoJsonLayer.current._layers
        ).find(({ feature }: any) => feature.id === countryRef.current);

        selectedCountry &&
            setTimeout(() => {
                mapRef?.target.flyToBounds(selectedCountry.getBounds(), {
                    paddingBottomRight: isExpanded ? [100, 0] : [700, 0]
                });
            }, 500);
    };

    const mapInteractions = (_feature: Feature, layer: Layer) => {
        continentRef.current === NONEOPTION &&
            layer.on({
                mouseout: resetHighlight,
                mouseover: highlightFeature,
                click: clickedCountry
            });
    };

    useEffect(() => {
        if (country === ALLOPTION || county === ALLOPTION) {
            fullCountryRef.current = false;
        } else if (
            (country === NONEOPTION && continent !== ALLOPTION) ||
            (county !== ALLOPTION && county !== NONEOPTION) ||
            (country !== ALLOPTION && county === NONEOPTION)
        ) {
            fullCountryRef.current = true;
        }

        setTimeout(() => {
            if (
                continent === ALLOPTION ||
                (continent === NONEOPTION &&
                    country === ALLOPTION &&
                    county === NONEOPTION)
            ) {
                mapRef?.target.flyTo(centerMap, 2);
            } else if (country !== ALLOPTION && county === NONEOPTION) {
                const selectedCountry: any = Object.values(
                    geoJsonLayer.current._layers
                ).find(({ feature }: any) => feature.id === country);

                mapRef?.target.invalidateSize();
                selectedCountry &&
                    mapRef?.target.flyToBounds(selectedCountry.getBounds());
            } else if (country !== ALLOPTION && county === ALLOPTION) {
                const selectedCountry: any = Object.values(
                    geoJsonLayer.current._layers
                ).find(({ feature }: any) => feature.id === country);

                mapRef?.target.invalidateSize();
                selectedCountry &&
                    mapRef?.target.flyToBounds(selectedCountry.getBounds());
            } else if (county !== NONEOPTION && county !== ALLOPTION) {
                const selectedCounty: any = Object.values(
                    geoJsonLayer.current._layers
                ).find(({ feature }: any) => feature.id === county);

                mapRef?.target.invalidateSize();
                selectedCounty &&
                    mapRef?.target.flyToBounds(selectedCounty.getBounds());
            }
        }, 500);
    }, [continent, country, county, mapRef?.target]);

    function handleWindowSizeChange() {
        setIsMobile(
            window.matchMedia(`(max-width: ${twTheme`screens.sm.max`})`).matches
        );
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [handleResize]);

    return (
        <>
            <div id="map" tw="relative px-2 max-w-[90%] lg:max-w-[85%] md:max-w-[100%]">
                <div tw="relative z-[100] ml-[4rem] md:ml-0">
                    <MapContainer
                        whenReady={setMapRef as any}
                        center={centerMap}
                        zoom={2}
                        minZoom={1}
                        maxZoom={5}
                        zoomControl={false}
                        doubleClickZoom={true}
                        trackResize={false}
                        touchZoom={isMobile}
                        scrollWheelZoom={false}
                        style={{
                            backgroundColor: "white"
                        }}
                        dragging={true}
                    >
                        {main_query_data?.worldData.finalData &&
                        (county === NONEOPTION || countryData?.finalData) ? (
                            <GeoJSON
                                ref={geoJsonLayer}
                                data={
                                    {
                                        type: "FeatureCollection",
                                        features:
                                            countryData && county !== NONEOPTION
                                                ? countryData.countyValues
                                                : main_query_data.countryValues
                                    } as FeatureCollection
                                }
                                onEachFeature={mapInteractions}
                                key={mapView}
                                style={(region) =>
                                    regionStyle(
                                        region as CountryDataShape,
                                        country,
                                        county !== NONEOPTION
                                            ? countryData!.finalData!.headcount
                                            : main_query_data.worldData
                                                  .finalData!.headcount,
                                        mapView
                                    )
                                }
                            />
                        ) : (
                            <Loader message="loading_map_data" />
                        )}
                        {country === ALLOPTION && !isMobile && <ZoomControl />}
                    </MapContainer>
                </div>
                {tooltipInfo && !fullCountryRef.current && !isMobile && (
                    <CountryCard
                        target={tooltipInfo.target}
                        originalEvent={tooltipInfo.originalEvent}
                    />
                )}
                <div css={[fullCountryRef.current ? tw`visible` : tw`hidden`]}>
                    <FullCountryCard
                        stateRef={fullCountryRef}
                        shiftCallback={shiftCountry}
                    />
                </div>
            </div>
        </>
    );
};

export default Map;
