import { t } from "i18next";
import { CountryDataShape } from "interfaces/world-poverty-context.interface";
import { TargetShape } from "interfaces/feature-shape.interface";
import { mapOptions, countyMapOptions } from "utils/Groups";
import { ColorInGradient, HexToRGB } from "./RGBColors";
import { theme } from "twin.macro";

type Range = {
    start: number;
    end: number;
};

export const mapPercents: { [name: string]: Range } = {
    // end values are set using the ceiling of the maximum value in a given subset
    // used to normalize the color distribution
    poverty_relative_to_the_region: {
        start: 0,
        end: 100
    },
    county_poverty_relative_to_the_country: {
        start: 0,
        end: 9
    },
    poverty_relative_to_the_world: {
        start: 0,
        end: 12
    },
    poverty_relative_to_the_country: {
        start: 0,
        end: 100
    }
};

export const regionStyle = (
    region: CountryDataShape,
    country: string,
    denominator: number,
    mapView: String
) => {
    const weight = region.id === country ? 3 : 0.5;
    if (region.finalData) {
        if (mapView === mapOptions[0]) {
            switch (region.finalData.stableTrack) {
                case 0:
                    return {
                        fillColor: theme`colors.map.gray`,
                        fillOpacity: 1,
                        weight: weight,
                        color: "black"
                    };
                case 1:
                    return {
                        fillColor: theme`colors.map.green`,
                        fillOpacity: 1,
                        weight: weight,
                        color: "black"
                    };
                case 2:
                    return {
                        fillColor: theme`colors.map.yellow`,
                        fillOpacity: 1,
                        weight: weight,
                        color: "black"
                    };
                case 3:
                    return {
                        fillColor: theme`colors.map.red`,
                        fillOpacity: 1,
                        weight: weight,
                        color: "black"
                    };
                default:
                    return {
                        fillColor: theme`colors.map.white`,
                        fillOpacity: 1,
                        weight: weight,
                        color: "black"
                    };
            }
        } else {
            // normalizing factors set with respect to maximum value from the given set
            const normalizeWorldPop = 100 / 12;
            const nomralizeCountyPop = 100 / 9;
            let povertyPercent;
            if (mapView === countyMapOptions[1]) {
                povertyPercent =
                    (nomralizeCountyPop * region.finalData.headcount) /
                    denominator;
            } else if (mapView === mapOptions[1]) {
                povertyPercent =
                    (normalizeWorldPop * region.finalData.headcount) /
                    denominator;
            } else {
                povertyPercent =
                    region.finalData.headcount /
                    region.finalData.totalPopulation;
            }
            const startColor = HexToRGB(theme`colors.map.gray`);
            const endColor = HexToRGB(theme`colors.map.red`);

            const color = ColorInGradient(startColor, endColor, povertyPercent);
            return {
                fillColor: color
                    ? `rgb(${color.r}, ${color.g}, ${color.b})`
                    : theme`colors.map.white`,
                fillOpacity: 1,
                weight: weight,
                color: "black"
            };
        }
    } else {
        return {
            fillColor: "white",
            fillOpacity: 1,
            weight: weight,
            color: "black"
        };
    }
};

export const rateTextFormatting = (
    target: TargetShape,
    denominator: number,
    thresh: string,
    mapView: string
) => {
    switch (mapView) {
        case mapOptions[0]:
            switch (target.feature.finalData?.stableTrack) {
                case 0:
                    return t("poverty_below_three_percent");
                case 1:
                case 2:
                case 3:
                    return t(`live_in_${thresh}`);
                default:
                    return t("no_data_available");
            }
        case mapOptions[1]:
            const map_one_rate =
                (100 * target.feature.finalData!.headcount) / denominator;
            return `${map_one_rate.toFixed(1)}% ${t(
                "of_the_global_population_in"
            )} ${t(thresh)} ${t("live_in_this_country")}`;
        case countyMapOptions[1]:
            const county_one_rate =
                (100 * target.feature.finalData!.headcount) / denominator;
            return `${county_one_rate.toFixed(1)}% ${t(
                "of_this_countrys_population_in"
            )} ${t(thresh)} ${t("live_in_this_region")}`;
        case mapOptions[2]:
            const map_two_rate =
                (100 * target.feature.finalData!.headcount) /
                target.feature.finalData!.totalPopulation;
            return `${map_two_rate.toFixed(1)}% ${t(
                "of_this_countrys_population_live_in"
            )} ${t(thresh)}`;
        case countyMapOptions[2]:
            const county_two_rate =
                (100 * target.feature.finalData!.headcount) /
                target.feature.finalData!.totalPopulation;
            return `${county_two_rate.toFixed(1)}% ${t(
                "of_this_regions_population_live_in"
            )} ${t(thresh)}`;
        default:
            return "";
    }
};

export const swapMapFilters = (mapView: string) => {
    switch (mapView) {
        case mapOptions[1]:
            return countyMapOptions[1];
        case mapOptions[2]:
            return countyMapOptions[2];
        case countyMapOptions[1]:
            return mapOptions[1];
        case countyMapOptions[2]:
            return mapOptions[2];
        default:
            return mapOptions[0];
    }
};
