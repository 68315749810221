import { useWorldPoverty } from "context/worldPoverty";
import currentEscape from "assets/images/icon-current-escape.svg";
import povertyEscape from "assets/images/icon-poverty-escape.svg";
import povertyFall from "assets/images/icon-poverty-fall.svg";
import targetEscape from "assets/images/icon-target-escape.svg";
import NumbersStringify from "utils/NumbersStringify";
import { HeroSectionShape } from "interfaces/herosection.interface";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const InfoBoxes = ({ heroSectionData }: HeroSectionShape) => {
    const { main_query_data, setFullModal, year } = useWorldPoverty();
    const endYear = 2031;

    return (
        <div tw="flex flex-row items-start justify-start mr-[10vw] lg:scale-75 lg:mr-auto lg:ml-auto lg:left-[15rem] sm:scale-100 sm:flex-col sm:my-10 sm:w-[80%]">
            <div tw="flex flex-col items-start text-[0.9rem] pr-4 sm:py-8 sm:border-t-2 sm:border-b-2 sm:border-black sm:w-full sm:pr-0 sm:mx-1">
                <div tw="flex flex-row pb-8">
                    <img src={targetEscape} alt="Target Escape" />
                    <div tw="flex flex-col pl-4 w-full">
                        <button
                            tw="text-left"
                            onClick={() =>
                                setFullModal({
                                    name: "fullModal",
                                    value: true,
                                    section: "explained_target_escape"
                                })
                            }
                        >
                            <p tw="uppercase tracking-[2px]">
                                {t("target_escape_rate")}
                            </p>
                            <p tw="font-bold">
                                {year !== endYear
                                    ? heroSectionData.targetEscapeRate.toFixed(
                                          2
                                      )
                                    : "--"}
                                <span tw="whitespace-pre"> </span>
                                {t("people_per_sec")}
                            </p>
                        </button>
                    </div>
                </div>
                <div tw="flex flex-row">
                    <img src={currentEscape} alt="Current Escape" />
                    <div tw="flex flex-col pl-4 w-full">
                        <button
                            tw="text-left"
                            onClick={() =>
                                setFullModal({
                                    name: "fullModal",
                                    value: true,
                                    section: "explained_current_escape"
                                })
                            }
                        >
                            <p tw="uppercase tracking-[2px]">
                                {t("current_escape_rate")}
                            </p>
                            <p tw="font-bold">
                                {heroSectionData.currEscapeRate.toFixed(2)}
                                <span tw="whitespace-pre"> </span>
                                {t("people_per_sec")}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <div tw="flex flex-col items-start text-[0.9rem] sm:mt-8 pr-4 sm:pb-8 sm:border-b-2 sm:border-black sm:w-full sm:pr-0 sm:mx-1">
                <div tw="flex flex-row pb-8">
                    <img src={povertyEscape} alt="Escaped Today" />
                    <div tw="flex flex-col pl-4">
                        <p tw="uppercase tracking-[2px]">
                            {t("escaped_poverty_today")}
                        </p>
                        <p tw="font-bold">
                            {NumbersStringify(heroSectionData.escapedToday)}
                        </p>
                    </div>
                </div>
                <div tw="flex flex-row">
                    <img src={povertyFall} alt="Target Escape" />
                    <div tw="flex flex-col pl-4">
                        <p tw="uppercase tracking-[2px]">
                            {t("fell_into_poverty_today")}
                        </p>
                        <p tw="font-bold">
                            {NumbersStringify(heroSectionData.fellIntoPovToday)}
                        </p>
                    </div>
                </div>
            </div>
            <div tw="sm:flex sm:flex-row text-[1.8vh] sm:w-full sm:items-center sm:py-8">
                <span tw="hidden sm:flex sm:h-[3rem] sm:w-[3rem] sm:rounded-full sm:bg-map-red  sm:mx-1"></span>
                <div tw="hidden sm:flex sm:flex-col sm:ml-5">
                    <button
                        onClick={() =>
                            setFullModal({
                                name: "fullModal",
                                value: true,
                                section: "explained_offtrack"
                            })
                        }
                        tw="flex flex-col justify-start items-start"
                    >
                        <p tw="uppercase tracking-[2px]">
                            {t("off_track")}&nbsp;
                            {t("sdg_one")}
                        </p>
                        <p tw="font-bold font-workSans">
                            {main_query_data &&
                                NumbersStringify(heroSectionData.offTrack)}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
};
