import { useWorldPoverty } from "context/worldPoverty";
import { t } from "i18next";
import { PercentInPovertyProps } from "interfaces/herosection.interface";
import { dailySpendingValues, povertyThresholds } from "utils/Groups";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const PercentInPoverty = ({
    percentInPoverty
}: PercentInPovertyProps) => {
    const { daily_spending } = useWorldPoverty();
    return (
        <div tw="absolute left-[26rem] top-[5rem] flex flex-row sm:w-0 sm:h-0">
            <span tw="h-[5vh] w-[5vh] rounded-full bg-map-red sm:hidden"></span>
            <div tw="absolute w-[18rem] left-[4vh] ml-5 sm:hidden">
                <p tw="font-bold inline text-map-red text-lg">
                    {percentInPoverty.toFixed(1)}%
                </p>
                <span tw="whitespace-pre"> </span>
                <p tw="uppercase inline text-sm">
                    {t("living_in")}
                    <span tw="whitespace-pre"> </span>
                    {t(
                        povertyThresholds[
                            dailySpendingValues.findIndex(
                                (item) => item === daily_spending
                            )
                        ]
                    )}
                </p>
            </div>
        </div>
    );
};
