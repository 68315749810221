import Logo from "assets/images/logo-half-white.svg";
import { useWorldPoverty } from "context/worldPoverty";
import closeImg from "assets/images/close.svg";
import { closeClick, modalTypes } from "utils/Modal";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { mapOptions } from "utils/Groups";

const MobileLense = () => {
    const { mapView, setActiveFilters, setFullModal } = useWorldPoverty();

    return (
        <>
            <div tw="fixed top-4 left-4">
                <img src={Logo} alt="Logo" tw="h-12 w-12" />
            </div>
            <button tw="fixed top-6 right-6">
                <img
                    src={closeImg}
                    alt="Close Modal"
                    tw="h-4 w-4"
                    onClick={() =>
                        setFullModal(closeClick(modalTypes.mobileLenses))
                    }
                />
            </button>
            <div tw="flex flex-col">
                {mapOptions.map((option) => (
                    <button
                        tw="flex bg-white rounded-full m-3 px-6 border-2 border-gray-400"
                        key={option}
                        css={[
                            mapView === option &&
                                tw`bg-map-red text-white font-bold border-2 border-map-red`
                        ]}
                        onClick={() => {
                            setActiveFilters({
                                name: "mapView",
                                value: option
                            });
                        }}
                    >
                        {t(option)}
                    </button>
                ))}
            </div>
        </>
    );
};

export default MobileLense;
