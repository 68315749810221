import { useWorldPoverty } from "context/worldPoverty";
import { yearOptions } from "utils/Groups";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const YearDropdown = () => {
    const { setActiveFilters } = useWorldPoverty();
    const yearList = yearOptions();
    return (
        <>
            <div tw="flex flex-col w-full overflow-auto h-60">
                {yearList.map((year: number) => (
                    <button
                        key={year}
                        tw="border-2 text-left px-9 py-2 border-gray-200 bg-white w-full"
                        onClick={() =>
                            setActiveFilters({
                                name: "year",
                                value: year
                            })
                        }
                    >
                        {year}
                    </button>
                ))}
            </div>
        </>
    );
};

export default YearDropdown;
