import { useWorldPoverty } from "context/worldPoverty";
import SubscribeForm from "components/Modal/SubscribeForm";
import Explained from "components/Modal/Explained";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import MobileFilter from "components/Mobile/MobileFilter";
import { modalTypes } from "utils/Modal";
import MobileLense from "components/Mobile/MobileLense";

const Modal = () => {
    const { fullModal } = useWorldPoverty();

    let modalComponent;

    switch (fullModal.section) {
        case modalTypes.subscribe:
            modalComponent = <SubscribeForm />;
            break;
        case modalTypes.explained_target_escape:
            modalComponent = (
                <Explained
                    modal={modalTypes.explained_target_escape}
                    modalText={{
                        header: "modal_texts.target_title",
                        body: "modal_texts.target_text"
                    }}
                />
            );
            break;
        case modalTypes.explained_offtrack:
            modalComponent = (
                <Explained
                    modal={modalTypes.explained_offtrack}
                    modalText={{
                        header: "modal_texts.offtrack_title",
                        body: "modal_texts.offtrack_text"
                    }}
                />
            );
            break;
        case modalTypes.explained_current_escape:
            modalComponent = (
                <Explained
                    modal={modalTypes.explained_current_escape}
                    modalText={{
                        header: "modal_texts.current_title",
                        body: "modal_texts.current_text"
                    }}
                />
            );
            break;
        case modalTypes.explained_year_end:
            modalComponent = (
                <Explained
                    modal={modalTypes.explained_year_end}
                    modalText={{
                        header: "modal_texts.end_title",
                        body: "modal_texts.end_text"
                    }}
                />
            );
            break;
        case modalTypes.mobileFilters:
            modalComponent = <MobileFilter />;
            break;
        case modalTypes.mobileLenses:
            modalComponent = <MobileLense />;
            break;
        default:
            modalComponent = <></>;
            break;
    }

    return (
        <section
            tw="fixed top-0 left-0 w-screen h-screen bg-gray-700/95 z-[999] flex flex-col justify-center items-center invisible"
            css={[fullModal.isModalOpen && tw`visible`]}
        >
            {modalComponent}
        </section>
    );
};

export default Modal;
