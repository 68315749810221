import { CSSObjectWithLabel } from "react-select";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export const DesktopStyling = {
    control: (base: CSSObjectWithLabel) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        backgroundColor: "white"
    }),
    option: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: "none",
        borderBottom: "1px solid darkgray",
        color: "gray",
        paddingLeft: "8px",
        paddingRight: "8px",
        "&:hover": {
            backgroundColor: "lightgray"
        }
    }),
    menu: (base: CSSObjectWithLabel) => ({
        ...base,
        paddingLeft: "8px",
        paddingRight: "8px"
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "black"
    })
};

export const MobileStyling = {
    control: (base: CSSObjectWithLabel) => ({
        ...base,
        border: 0,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: "black",
        paddingRight: "8px",
        paddingTop: "8px",
        paddingBottom: "8px"
    }),
    option: (base: CSSObjectWithLabel) => ({
        ...base,
        backgroundColor: "none",
        borderBottom: "1px solid darkgray",
        color: "gray",
        fontFamily: "Work Sans",
        paddingLeft: "8px",
        paddingRight: "8px",
        "&:hover": {
            backgroundColor: "lightgray"
        }
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "white",
        backgroundColor: "black",
        paddingLeft: "8px"
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
        ...base,
        display: "none"
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "white"
    }),
    singleValue: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "white"
    })
};

export const CharLimit = 20;

export const CountryCardLong = tw`text-[32px]`;
export const CountryCardRegular = tw`text-[40px]`;

export const FullCardLong = tw`text-[30px]`;
export const FullCardRegular = tw`text-[40px]`;
