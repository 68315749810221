import { t } from "i18next";
import { socials } from "utils/Socials";
import LogoWhite from "assets/images/logo-white.svg";
import IfadLogo from "assets/images/ifad_logo.png";
import BMZLogo from "assets/images/bmz_logo.png";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Footer = () => (
    <section tw="mt-8 md:pt-6 w-[100%] bg-map-red text-white z-[101] min-h-[25rem] md:min-h-[60rem]">
            <div tw="relative flex flex-col sm:py-12 items-center">
                <div tw="flex flex-row gap-48 py-4 lg:gap-0 md:flex-col">
                    <div tw="w-[237px]">
                        <div tw="flex flex-col">
                            <h1 tw="font-workSansBold text-[45px] leading-[3rem] uppercase">
                                {t("title")}
                            </h1>
                            <div tw="my-4">{t("by")}</div>
                            <div tw="flex flex-row items-center">
                                <div tw="mr-4">
                                    <img
                                        src={LogoWhite}
                                        alt="World Data Lab"
                                        tw="h-10 w-10"
                                    />
                                </div>
                                <p tw="font-workSans uppercase">{t("world_data_lab")}</p>
                            </div>
                        </div>
                    </div>
                    <div tw="flex flex-col ">
                        <div tw="font-workSans">
                        <p tw="font-workSansBold sm:mt-6">{t("contact_us")}</p>
                            <p tw="my-2">{t("hello_email")}</p>
                        </div>
                        <div tw="flex flex-row">
                            {socials.map((s) => (
                                <a
                                    href={s.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    tw="bg-white rounded-full m-2"
                                    key={s.name}
                                >
                                    <img
                                        src={s.img}
                                        alt={s.name}
                                        tw="h-8 w-8 p-1.5"
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div tw="flex flex-col relative bg-map-red w-[15rem] z-[101] sm:m-0 sm:mt-6 sm:pb-6">
                        <p tw="lowercase">{t("supported_by")}:</p>
                        <div tw="flex flex-col m-4 mt-8 justify-start items-start gap-4">
                            <img src={BMZLogo} alt="Federal Ministry Logo"/>
                            <img src={IfadLogo} alt="IFAD Logo" tw="w-32"/>
                        </div>
                    </div>
                </div>
            </div>
    </section>
);

export default Footer;
