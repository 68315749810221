import { useState } from "react";
import BurgerImage from "assets/images/burger.svg";
import BurgerBlack from "assets/images/burger-black.svg";
import Menu from "components/Menu";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

const Burger = () => {
    const [showMenu, setMenu] = useState(false);

    return (
        <>
            <div css={[showMenu ? tw`visible` : tw`hidden`]}>
                <Menu closeMenuCallback={() => setMenu(false)} />
            </div>
            <button tw="relative place-self-center md:hidden">
                <img
                    src={BurgerImage}
                    alt="Menu"
                    tw="absolute h-8 w-8 sm:invisible"
                    onClick={() => setMenu(true)}
                />
                <img
                    src={BurgerBlack}
                    alt="Menu"
                    tw="invisible sm:visible h-8 w-8"
                    onClick={() => setMenu(true)}
                />
            </button>
        </>
    );
};

export default Burger;
