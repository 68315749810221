import { useCallback } from "react";

const useLeaflet = (mapRef: any) => {
    const handleResize = useCallback(() => {
        mapRef?.target.invalidateSize();
        window.innerWidth < 900
            ? mapRef?.target.setZoom(1)
            : mapRef?.target.setZoom(1.5);
    }, [mapRef?.target]);

    return { handleResize };
};

export default useLeaflet;
