import { t } from "i18next";
import useScrollPosition from "hooks/useScrollPosition";
import { HeroSectionRef, MapSectionRef } from "interfaces/main-ref.interface";
import Globe from "assets/images/globe.svg";
import Map from "assets/images/map.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Bookmark = ({
    heroSectionRef,
    mapSectionRef
}: HeroSectionRef & MapSectionRef) => {
    const { scrollPosition } = useScrollPosition();

    return (
        <div tw="sm:hidden z-[100] fixed left-0 top-[20%] flex flex-col h-40 w-36 text-white">
            <div tw="my-2 h-16 w-full flex flex-row items-center justify-center bg-map-red">
                <span tw="flex flex-row items-center before:block before:absolute before:inset-[40px] before:-right-3 before:top-2 before:skew-x-[-35deg] before:bg-map-red before:z-[-10] before:h-8 after:block after:absolute after:inset-[40px] after:-right-3 after:bottom-0 after:skew-x-[35deg] after:bg-map-red after:z-[-10] after:h-8">
                    {scrollPosition < 300 ? (
                        <button
                            onClick={() =>
                                mapSectionRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                            }
                            tw="flex flex-row"
                        >
                            <div tw="flex flex-row text-center justify-center">
                                <img src={Map} alt="Map" tw="mr-2" />
                                <p tw="uppercase">{t("map")}</p>
                            </div>
                        </button>
                    ) : (
                        <button
                            onClick={() =>
                                heroSectionRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                })
                            }
                            tw="flex flex-row"
                        >
                            <div tw="flex flex-row">
                                <p tw="uppercase tracking-[2px]">
                                    {t("ticker")}
                                </p>
                                <img src={Globe} alt="Globe" tw="ml-2" />
                            </div>
                        </button>
                    )}
                </span>
            </div>
            <div tw="relative bg-black my-2 h-16 w-full flex flex-col items-center justify-center">
                <span tw="flex flex-row items-center before:block before:absolute before:inset-[40px] before:-right-3 before:top-0 before:skew-x-[-35deg] before:bg-black before:z-[-10] before:h-8 after:block after:absolute after:inset-[32px] after:-right-3 after:skew-x-[35deg] after:bg-black after:z-[-10] after:h-8">
                    {scrollPosition < 300 ? (
                        <button className="hs-cta-trigger-button hs-cta-trigger-button-75725267674">
                            <div tw="pl-6">
                                <p tw="uppercase tracking-[2px] text-left">
                                    {t("request_data")}
                                </p>
                            </div>
                        </button>
                    ) : (
                        <button className="hs-cta-trigger-button hs-cta-trigger-button-75725267674">
                            <div tw="pl-6">
                                <p tw="uppercase tracking-[2px] text-left">
                                    {t("download_data")}
                                </p>
                            </div>
                        </button>
                    )}
                </span>
            </div>
        </div>
    );
};

export default Bookmark;
