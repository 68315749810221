import { t } from "i18next";
import { LoaderProps } from "interfaces/loader.interface";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Loader = ({ message }: LoaderProps) => (
    <div tw="flex items-center flex-col text-sm text-gray-500 gap-[1rem] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 200 200"
            fill="none"
            color={theme`colors.purple.600`}
        >
            <defs>
                <linearGradient id="spinner-secondHalf">
                    <stop
                        offset="0%"
                        stopOpacity="0"
                        stopColor="currentColor"
                    />
                    <stop
                        offset="100%"
                        stopOpacity="0.5"
                        stopColor="currentColor"
                    />
                </linearGradient>
                <linearGradient id="spinner-firstHalf">
                    <stop
                        offset="0%"
                        stopOpacity="1"
                        stopColor="currentColor"
                    />
                    <stop
                        offset="100%"
                        stopOpacity="0.5"
                        stopColor="currentColor"
                    />
                </linearGradient>
            </defs>

            <g strokeWidth="8">
                <path
                    stroke="url(#spinner-secondHalf)"
                    d="M 4 100 A 96 96 0 0 1 196 100"
                />
                <path
                    stroke="url(#spinner-firstHalf)"
                    d="M 196 100 A 96 96 0 0 1 4 100"
                />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    d="M 4 100 A 96 96 0 0 1 4 98"
                />
            </g>
            <animateTransform
                from="0 0 0"
                to="360 0 0"
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1500ms"
            />
        </svg>
        <p>{t(message)}</p>
    </div>
);

export default Loader;
