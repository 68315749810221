import { MutableRefObject, useState } from "react";
import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Tick from "assets/images/tick.svg";
import Plus from "assets/images/plus.svg";
import ArrowDown from "assets/images/dropdown.svg";
import { AgeGroup } from "interfaces/world-poverty-context.interface";
import { ageGroups, customAges } from "utils/Groups";
import { ageStringify } from "utils/AgeStringify";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

interface DropdownProps {
    dropdownRef: MutableRefObject<HTMLDivElement | null>;
}

const Dropdown = ({ dropdownRef }: DropdownProps) => {
    const { ageGender, setActiveFilters } = useWorldPoverty();
    const [customGroup, setCustomGroup] = useState(false);
    const [lowAge, setLowAge] = useState(customAges[0]);
    const [lowDropdown, setLowDropdown] = useState(false);
    const [highAge, setHighAge] = useState(customAges[customAges.length - 1]);
    const [highDropdown, setHighDropdown] = useState(false);

    const handleClick = (group: AgeGroup) => {
        let newGroup = group;
        if (group === ageGender.age) {
            newGroup = ageGroups[0];
        }

        setActiveFilters({
            name: "ageGender",
            value: {
                age: newGroup,
                gender: ageGender.gender
            }
        });
    };

    return (
        <div
            tw="absolute min-w-[180%] shadow flex flex-col p-2 z-[1] rounded-md bg-white text-sm mx-2 sm:relative sm:ml-0 sm:min-w-[100%]"
            tabIndex={0}
            ref={dropdownRef}
        >
            {customGroup ? (
                <div tw="min-w-[330px] sm:w-[100%]">
                    <p tw="uppercase text-sm text-brown tracking-[2px]">
                        {t("custom_age_group")}
                    </p>
                    <p tw="text-[14px]">{t("select_the_two_ages")}</p>
                    <div tw="flex flex-row items-center py-8">
                        {t("from")}
                        <div tw="relative">
                            <button
                                tw="bg-map-lightRed w-12 px-2 py-1 mx-2 flex flex-row justify-between items-center"
                                onClick={() => setLowDropdown(!lowDropdown)}
                            >
                                <p>{lowAge}</p>
                                <img src={ArrowDown} alt="Select Low Age" />
                            </button>
                            {lowDropdown && (
                                <div tw="fixed bg-white shadow z-[999] divide-y divide-gray-400 w-12 mx-2 flex flex-col">
                                    {customAges
                                        .filter((age) => Number(age) < highAge)
                                        .map((age) => (
                                            <button
                                                key={age}
                                                tw="px-2 py-1"
                                                onClick={() => {
                                                    setLowAge(Number(age));
                                                    setLowDropdown(false);
                                                }}
                                            >
                                                <p tw="w-full text-left">
                                                    {age ===
                                                        customAges[
                                                            customAges.length -
                                                                1
                                                        ] && ">"}
                                                    {age}
                                                </p>
                                            </button>
                                        ))}
                                </div>
                            )}
                        </div>

                        {t("to")}
                        <div>
                            <button
                                tw="bg-map-lightRed w-12 px-2 py-1 mx-2 flex flex-row justify-between items-center"
                                onClick={() => {
                                    setHighDropdown(!highDropdown);
                                }}
                            >
                                <p>
                                    {highAge ===
                                        customAges[customAges.length - 1] &&
                                        ">"}
                                    {highAge}
                                </p>
                                <img src={ArrowDown} alt="Select High Age" />
                            </button>
                            {highDropdown && (
                                <div tw="fixed bg-white shadow z-[999] divide-y divide-gray-400 w-12 mx-2 flex flex-col">
                                    {customAges
                                        .filter((age) => age > lowAge)
                                        .map((age) => (
                                            <button
                                                key={age}
                                                tw="px-2 py-1"
                                                onClick={() => {
                                                    setHighAge(age);
                                                    setHighDropdown(false);
                                                }}
                                            >
                                                <p tw="w-full text-left">
                                                    {age ===
                                                        customAges[
                                                            customAges.length -
                                                                1
                                                        ] && ">"}
                                                    {age}
                                                </p>
                                            </button>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div tw="flex flex-row w-full justify-end">
                        <button
                            tw="border border-map-red text-map-red rounded-full px-4 py-1 mr-2"
                            onClick={() => {
                                setCustomGroup(false);
                                setLowAge(customAges[0]);
                                setHighAge(customAges[customAges.length - 1]);
                            }}
                        >
                            {t("cancel")}
                        </button>
                        <button
                            tw="border border-map-red text-white bg-map-red rounded-full px-4 py-1"
                            onClick={() => {
                                setCustomGroup(false);
                                const newAgeGroup = {
                                    group: {
                                        from: lowAge,
                                        to:
                                            highAge ===
                                            customAges[customAges.length - 1]
                                                ? null
                                                : highAge
                                    }
                                };

                                handleClick(newAgeGroup);
                            }}
                        >
                            {t("confirm")}
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div tw="divide-y-2 divide-gray-400 w-full flex flex-col">
                        {ageGroups.map((ageGroup) => (
                            <button
                                key={ageGroup.name}
                                tw="px-1 mx-2 hover:bg-gray-400 hover:text-gray-600 text-left text-gray-600 py-2"
                                onClick={() => {
                                    handleClick(ageGroup);
                                }}
                                css={[
                                    ageGender.age.group.to ===
                                        ageGroup.group.to &&
                                        ageGender.age.group.from ===
                                            ageGroup.group.from &&
                                        tw`bg-map-red text-white font-bold`
                                ]}
                            >
                                <div tw="flex flex-row">
                                    <div
                                        tw="border border-map-red"
                                        css={[
                                            ageGender.age.group.to ===
                                                ageGroup.group.to &&
                                                ageGender.age.group.from ===
                                                    ageGroup.group.from &&
                                                tw`border-white`
                                        ]}
                                    >
                                        <img
                                            src={Tick}
                                            alt="Checked"
                                            tw="p-1 invisible"
                                            css={[
                                                ageGender.age.group.to ===
                                                    ageGroup.group.to &&
                                                    ageGender.age.group.from ===
                                                        ageGroup.group.from &&
                                                    tw`visible`
                                            ]}
                                        />
                                    </div>
                                    <p tw="ml-2">{ageGroup.name}</p>
                                </div>
                            </button>
                        ))}
                        {!ageGroups
                            .map((grouping) => grouping.name)
                            .includes(ageStringify(ageGender.age)) && (
                            <div tw="px-1 mx-2 bg-map-red text-white text-left font-bold py-2">
                                <div tw="flex flex-row justify-between items-center">
                                    <div tw="flex flex-row">
                                        <div tw="border border-white">
                                            <img
                                                src={Tick}
                                                alt="Check"
                                                tw="p-1"
                                            />
                                        </div>
                                        <p tw="ml-2">
                                            {ageStringify(ageGender.age)}
                                        </p>
                                    </div>
                                    <button
                                        tw="justify-center"
                                        onClick={() => {
                                            setActiveFilters({
                                                name: "ageGender",
                                                value: {
                                                    age: ageGroups[0].group,
                                                    gender: ageGender.gender
                                                }
                                            });
                                        }}
                                    >
                                        <img
                                            src={Plus}
                                            alt="X"
                                            tw="rotate-45"
                                        />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div tw="self-center pt-2 w-full flex flex-row justify-center">
                        <button
                            tw="bg-map-red rounded-full text-white flex flex-row items-center px-2"
                            onClick={() => {
                                setCustomGroup(true);
                            }}
                        >
                            {t("custom_age_group")}
                            <img
                                src={Plus}
                                alt="Custom age group"
                                tw="m-2 pl-1 h-3"
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
