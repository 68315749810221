import DesktopYear from "components/YearSlider/DesktopYear";
import MobileYear from "components/YearSlider/MobileYear";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const YearSlider = () => {
    return (
        <section tw="fixed bottom-0 left-0 right-0 z-[500]">
            <div tw="fixed sm:hidden">
                <DesktopYear />
            </div>
            <div tw="hidden sm:block">
                <MobileYear />
            </div>
        </section>
    );
};

export default YearSlider;
