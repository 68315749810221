import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Share from "components/Share";
import { useState } from "react";
import { useWorldPoverty } from "context/worldPoverty";
import { yearOptions, endYear } from "utils/Groups";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

interface ToolTipProps {
    year: number;
}

const Tooltip = ({ year }: ToolTipProps) => {
    const { setFullModal } = useWorldPoverty();
    return (
        <div tw="relative select-none">
            <div tw="absolute bottom-5 w-[5rem] left-[-.9rem] bg-map-red">
                <div tw="flex flex-row select-none justify-center text-white">
                    {year === endYear ? t("END") : year}
                </div>
            </div>
            {year === endYear && (
                <div>
                    <div tw="absolute bottom-5 w-[15rem] left-[-14rem] top-[-2rem]">
                        <button
                            tw="text-map-red border-b-[0.1rem] pr-4 border-b-map-red tracking-wide"
                            onClick={() =>
                                setFullModal({
                                    name: "fullModal",
                                    value: true,
                                    section: "explained_year_end"
                                })
                            }
                        >
                            {t("what_does_this_mean")}
                        </button>
                    </div>
                </div>
            )}
            <div
                css={{
                    width: 0,
                    height: 0,
                    borderLeft: "1em solid transparent",
                    borderRight: "1em solid transparent",
                    borderTop: "1em solid #EE4322"
                }}
                tw="absolute bottom-[.3rem] left-[0.4rem]"
            ></div>
        </div>
    );
};

const SideArrow = () => (
    <div tw="relative">
        <div
            css={{
                width: 0,
                height: 0,
                borderTop: "0.5em solid transparent",
                borderBottom: "0.5em solid transparent",
                borderLeft: "0.5em solid #EE4322"
            }}
            tw="absolute left-[3.5rem] top-4"
        ></div>
        <div
            css={{
                width: 0,
                height: 0,
                borderTop: "0.5em solid transparent",
                borderBottom: "0.5em solid transparent",
                borderRight: "0.5em solid #EE4322"
            }}
            tw="absolute right-[3.5rem] top-4"
        ></div>
    </div>
);

const DesktopYear = () => {
    const { year, setActiveFilters } = useWorldPoverty();
    const yearsList = yearOptions();
    const [sliderYear, setSliderYear] = useState(year);

    const OnSliderChange = (newValue: number | number[]) => {
        setSliderYear(newValue as number);
        setActiveFilters({
            name: "year",
            value: newValue as number
        });
    };

    return (
        <div tw="flex flex-row fixed bottom-0 w-full h-[8rem] justify-center items-center bg-white z-[502] sm:hidden">
            <div tw="flex flex-row w-[50%]">
                <Slider
                    defaultValue={year}
                    value={sliderYear}
                    trackStyle={{ opacity: 0, height: 10 }}
                    railStyle={{ opacity: 0, height: 10 }}
                    min={yearsList.at(0)}
                    max={yearsList.at(yearsList.length - 1)}
                    tw="absolute z-10 w-[50%] drop-shadow-2xl"
                    handleRender={(renderProps) => {
                        return (
                            <div {...renderProps.props}>
                                <Tooltip year={sliderYear} />
                                <SideArrow />
                            </div>
                        );
                    }}
                    handleStyle={{
                        height: 40,
                        width: 40,
                        opacity: 1,
                        marginTop: -20,
                        backgroundColor: "white",
                        borderColor: "white",
                        boxShadow: "0 0.3rem 1rem gray",
                        transition: "left 0.05s"
                    }}
                    onChange={OnSliderChange}
                />

                <div tw="flex flex-row w-[50%] h-6 absolute">
                    <div tw="grid grid-cols-15 w-full border-r-gray-400 border-r-[0.1rem]">
                        {yearsList
                            .filter((year) => year < endYear)
                            .map((year) => (
                                <div tw="relative" key={year}>
                                    <div tw="flex flex-row items-center h-6 border-l-gray-400 border-l-[0.1rem]">
                                        <hr tw="self-center h-[0.1rem] w-full bg-gray-400" />
                                    </div>

                                    <div tw="absolute top-8 left-[-1.1rem]">
                                        {year % 2 === 0 ? (
                                            <p tw="text-black select-none w-[100%]">
                                                {year}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div></div>
                </div>
            </div>
            <Share />
        </div>
    );
};

export default DesktopYear;
