import { PersonShape } from "interfaces/person.interface";
import { useEffect, useState } from "react";
import Spritesheet from "react-responsive-spritesheet";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

interface personProps {
    personProp: PersonShape;
    dequeue: (id: number, person: PersonShape) => void;
}

const Person = ({ personProp, dequeue }: personProps) => {
    const [person, setPerson] = useState(personProp);

    useEffect(() => {
        const peopleInterval = setInterval(() => {
            if (person.position >= person.endPosition - 10) {
                setPerson({ ...person, fade: true });
            }
            if (person.position >= person.endPosition) {
                dequeue(person.id, person);
            }
            setPerson((person) => ({
                ...person,
                position: person.position + person.speed
            }));
        }, 20);

        return () => {
            clearInterval(peopleInterval);
        };
    }, [person, dequeue]);

    const transformPerson = {
        transform: `translateX(${person.position}vw)`,
        width: "6rem",
        opacity: "1",
        transition: "opacity 0.5s ease-in"
    };
    return (
        <div
            css={[
                person.fade
                    ? tw`transition-opacity opacity-20 duration-[1600ms]`
                    : tw`transition-opacity opacity-100 duration-[1600ms]`
            ]}
            tw="h-[6]"
        >
            {person.stop ? null : (
                <div css={[transformPerson]}>
                    <img
                        src={person.flag}
                        alt="person-flag"
                        tw="absolute top-[-0.7rem] left-[2.7rem] h-[1rem] w-[1.3rem]"
                    />
                    <Spritesheet
                        image={person.image}
                        widthFrame={216 / 2}
                        direction={`forward`}
                        heightFrame={162 / 2}
                        autoplay={true}
                        steps={19}
                        loop={true}
                        fps={30}
                    />
                </div>
            )}
        </div>
    );
};

export default Person;
