import geoJson from "data/worldGeoJSON.json";
import kenyaSubnat from "data/kenyaSubnationalGeoJSON.json";
import countryFlags from "data/countries_flags.json";
import { createContext, useContext, useReducer } from "react";
import { globalStateReducer } from "reducers/globalState";
import { ApolloVariables, ApolloQueryProps } from "interfaces/apollo.interface";
import {
    CountryDataShape,
    ContinentDataShape,
    CountyDataShape,
    GlobalState,
    MainQueryShape
} from "interfaces/world-poverty-context.interface";
import { ChildrenProps } from "interfaces/children.interface";
import { ActiveFiltersDispatchProps } from "interfaces/active-filters-dispatch.interface";
import { ModalDispatchProps } from "interfaces/modal-dispatch.interface";
import { handleReduceDataIds, handleFinalData } from "utils/Data";
import { useMainQuery } from "utils/__generated__/graphql";
import {
    ageGroups,
    dailySpendingValues,
    NONEOPTION,
    ALLOPTION
} from "utils/Groups";

let mergedMainData: MainQueryShape | null;
let updatedContinent: ContinentDataShape | null;
let updatedCountry: CountryDataShape | null;
let updatedCounty: CountyDataShape | null;

const initialState: GlobalState = {
    ageGender: {
        age: ageGroups[0],
        gender: "overall"
    },
    continent: NONEOPTION,
    continentData: null,
    country: ALLOPTION,
    countryData: null,
    county: NONEOPTION,
    countyData: null,
    region: "overall",
    main_query_data: null,
    mapView: "on_off_track_to_meet_sdg1",
    year: new Date().getFullYear(),
    daily_spending: 2.15,
    fullModal: {
        isModalOpen: false,
        section: ""
    },
    setActiveFilters: () => {},
    setFullModal: () => {}
};

const WorldPovertyClockContext = createContext(initialState);

export const WorldPovertyClockContextProvider = ({
    children
}: ChildrenProps) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);

    const mainQueryVariables: ApolloVariables = {
        year: state.year,
        dailySpending: state.daily_spending,
        regionDailySpending: dailySpendingValues[0],
        from: state.ageGender.age.group.from,
        to: state.ageGender.age.group.to
    };

    const { loading: isMainDataLoading, data: mainData }: ApolloQueryProps =
        useMainQuery({
            variables: mainQueryVariables
        });

    if (!isMainDataLoading && mainData) {
        const geoJsonIds = handleReduceDataIds(
            geoJson.features,
            (item) => item.id
        );
        const flagIds = handleReduceDataIds(
            countryFlags[0].data,
            (item) => item.iso3c
        );

        const kenSubnatGeoJsonIds = handleReduceDataIds(
            kenyaSubnat.features,
            (item) => item.properties.name
        );

        let worldAgeGenderData = mainData.mainQueryAgeGender;
        let worldRegionData = mainData.mainQueryRegion;
        let continentAgeGenderData =
            mainData.mainQueryAgeGender &&
            handleReduceDataIds(
                mainData.mainQueryAgeGender.continentValues,
                (item) => item.continent.name
            );
        let continentRegionData =
            mainData.mainQueryRegion &&
            handleReduceDataIds(
                mainData.mainQueryRegion.continentValues,
                (item) => item.continent.name
            );
        let countryRegionData =
            mainData.mainQueryRegion &&
            handleReduceDataIds(
                mainData.mainQueryRegion.countryValues,
                (item) => item.country.id
            );
        let countryAgeGenderData =
            mainData.mainQueryAgeGender &&
            handleReduceDataIds(
                mainData.mainQueryAgeGender.countryValues,
                (item) => item.country.id
            );
        let countyData =
            mainData.mainQueryCounty &&
            mainData.mainQueryCounty.countyValues &&
            handleReduceDataIds(
                mainData.mainQueryCounty.countyValues,
                (item) => item.country.id
            );

        const continentIds = [...Object.keys(continentRegionData)];
        const countryIds = [...Object.keys(geoJsonIds)];

        mergedMainData = {
            worldData: {
                finalData:
                    worldAgeGenderData && worldRegionData
                        ? handleFinalData(
                              state.ageGender.gender,
                              state.region,
                              worldAgeGenderData.ageValues[0].value,
                              worldRegionData.value
                          )
                        : null
            },
            continentalValues: [...continentIds].map((continentIds) => ({
                id: continentIds,
                finalData:
                    continentRegionData[continentIds] &&
                    continentAgeGenderData[continentIds]
                        ? handleFinalData(
                              state.ageGender.gender,
                              state.region,
                              continentAgeGenderData[continentIds].ageValues[0]
                                  .value,
                              continentRegionData[continentIds].value
                          )
                        : null,
                regionData: continentRegionData[continentIds],
                ageGenderData: continentAgeGenderData[continentIds],
                properties: {
                    name: continentIds
                }
            })),
            countryValues: [...countryIds].map((countryIds) => ({
                finalData:
                    countryAgeGenderData[countryIds] &&
                    countryRegionData[countryIds]
                        ? handleFinalData(
                              state.ageGender.gender,
                              state.region,
                              countryAgeGenderData[countryIds].ageValues[0]
                                  .value,
                              countryRegionData[countryIds].value
                          )
                        : null,
                ...geoJsonIds[countryIds],
                regionData: countryRegionData[countryIds],
                ageGenderData: countryAgeGenderData[countryIds],
                flag: flagIds[countryIds],

                countyValues: countyData[countryIds]
                    ? countyData[countryIds].values.map(
                          (countyId: { county: any; value: any }) => ({
                              finalData: countyId.value,
                              ...kenSubnatGeoJsonIds[countyId.county.name],
                              properties: { name: countyId.county.name },
                              id: countyId.county.name
                          })
                      )
                    : null
            }))
        };

        updatedCounty = updatedCountry
            ? updatedCountry.countyValues
                ? updatedCountry.countyValues.find(
                      (item: any) => item.properties.name === state.county
                  ) || null
                : null
            : null;

        updatedCountry =
            mergedMainData.countryValues.find(
                ({ id }) => id === state.country
            ) || null;

        updatedContinent =
            mergedMainData.continentalValues.find(
                (item) => item.properties.name === state.continent
            ) || null;
    }

    const setActiveFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { name, value },
            type: "SELECT_FILTERS"
        });

    const setFullModal = ({ name, value, section }: ModalDispatchProps) =>
        dispatch({
            payload: { name, value, section },
            type: "SELECT_MODAL"
        });

    const value = {
        ageGender: {
            age: state.ageGender.age,
            gender: state.ageGender.gender
        },
        continent: state.continent,
        continentData: updatedContinent,
        country: state.country,
        countryData: updatedCountry,
        county: state.county,
        countyData: updatedCounty,
        region: state.region,
        main_query_data: !isMainDataLoading && mainData ? mergedMainData : null,
        mapView: state.mapView,
        daily_spending: state.daily_spending,
        year: state.year,
        fullModal: {
            isModalOpen: state.fullModal.isModalOpen,
            section: state.fullModal.section
        },
        setActiveFilters,
        setFullModal
    };

    return (
        <WorldPovertyClockContext.Provider value={value}>
            {children}
        </WorldPovertyClockContext.Provider>
    );
};

export const useWorldPoverty = () => {
    const context = useContext(WorldPovertyClockContext);
    if (context === undefined)
        throw new Error(
            "useWorldPoverty needs to be within the WorldPovertyClockContext"
        );

    return context;
};
