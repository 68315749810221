import { t } from "i18next";
import logo from "assets/images/logo.svg";
import Burger from "components/Header/Burger";
import Filters from "components/Filters";
import { MapSectionRef } from "interfaces/main-ref.interface";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Header = ({ mapSectionRef }: MapSectionRef) => (
    <header tw="flex flex-row items-center w-screen bg-white fixed top-0 left-0 right-0 z-[530] pt-[2vh] pb-8">
        <div tw="flex flex-row w-full sm:w-screen px-4 justify-between">
            <div tw="flex flex-col  items-center text-center w-20 sm:flex-row sm:w-full tracking-[1px]">
                <div>
                    <img
                        src={logo}
                        alt="Logo"
                        tw="h-12 w-12 lg:w-8 lg:h-8 md:w-8 md:h-8 sm:h-10 sm:w-10"
                    />
                </div>
                <div tw="sm:hidden flex flex-row text-xs font-semiBold text-left lg:w-10 lg:text-[0.5rem] md:w-10 md:text-[0.5rem]">
                    <p>
                        {t("by")}
                        <span tw="whitespace-pre"> </span>
                        <span tw="uppercase">{t("world_data_lab")}</span>
                    </p>
                </div>
                <div tw="hidden sm:block ml-3 lg:text-[0.5rem] md:text-[0.5rem]">
                    <p tw="uppercase w-full">{t("title")}</p>
                </div>
            </div>
            <div tw="block sm:hidden">
                <Filters mapSectionRef={mapSectionRef} />
            </div>
            <Burger />
        </div>
    </header>
);

export default Header;
