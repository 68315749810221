import { useWorldPoverty } from "context/worldPoverty";
import { t } from "i18next";
import {
    ageGroups,
    dailySpendingValues,
    povertyThresholds
} from "utils/Groups";
import NumbersStringify from "utils/NumbersStringify";
import { MainTickerProp } from "interfaces/herosection.interface";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

export const MainTicker = ({ headcount, offTrack }: MainTickerProp) => {
    const { ageGender, region, main_query_data, daily_spending } =
        useWorldPoverty();
    return (
        <div tw="absolute left-[20rem] top-[12rem] sm:w-screen sm:flex sm:flex-col sm:justify-center sm:items-center sm:text-center sm:left-0 sm:top-[25rem]">
            <p
                css={[
                    ageGender.gender !== "overall" ||
                    region !== "overall" ||
                    ageGender.age !== ageGroups[0]
                        ? tw`text-black`
                        : tw`text-map-red`,
                    tw`relative font-workSansBold text-[4.5rem] tracking-[1px] z-[10] sm:self-center sm:text-center sm:left-0 sm:relative md:text-[3rem] left-[6.5rem]`
                ]}
            >
                {main_query_data
                    ? NumbersStringify(headcount)
                    : t("loading_hero_section")}
            </p>
            <div tw="absolute sm:relative text-left left-[7rem] text-[0.8rem] w-[30rem] md:w-[80%] sm:top-0 sm:left-0 sm:items-center sm:w-screen sm:text-center">
                {main_query_data && (
                    <>
                        <p tw="z-[10] w-full uppercase sm:flex sm:flex-row sm:justify-center sm:flex-wrap">
                            {t("people_in_the_world")}
                            <span tw="whitespace-pre"> </span>
                            {t("living in")}
                            <span tw="whitespace-pre"> </span>
                            {t(
                                povertyThresholds[
                                    dailySpendingValues.findIndex(
                                        (item) => item === daily_spending
                                    )
                                ]
                            )}
                        </p>
                        <div tw="w-full pt-2">
                            <p tw="inline z-[10] font-bold text-[0.9rem] uppercase sm:flex sm:flex-row sm:justify-center text-black sm:text-black">
                                {NumbersStringify(offTrack)}
                            </p>
                            <p tw="z-[10] inline w-full uppercase sm:flex sm:flex-row sm:justify-center">
                                <span tw="whitespace-pre"> </span>
                                {t("peopl_behind_sdg_one")}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
