import {
    HeroSectionReducerProp,
    HeroSectionState
} from "interfaces/herosection.interface";
import { heroReducerOptions } from "utils/Groups";

export const heroSectionReducer = (
    state: HeroSectionState,
    { type, payload }: HeroSectionReducerProp
) => {
    switch (type) {
        case heroReducerOptions.into:
            return {
                ...state,
                headcount: state.headcount + 1,
                fellIntoPovToday: state.fellIntoPovToday + 1,
                offTrack: state.offTrack + 1
            };
        case heroReducerOptions.escape:
            return {
                ...state,
                headcount: state.headcount - 1,
                escapedToday: state.escapedToday + 1,
                offTrack: state.offTrack - 1
            };
        case heroReducerOptions.offtrack:
            return {
                ...state,
                offTrack: state.offTrack + 1
            };
        case heroReducerOptions.set:
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
};
