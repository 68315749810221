import { useWorldPoverty } from "context/worldPoverty";
import { RadialWheelProp } from "interfaces/herosection.interface";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { radialColors, radialColorsForFilters } from "utils/RGBColors";
import { ageGroups } from "utils/Groups";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const RadialWheel = ({
    headcount,
    totalPopulation
}: RadialWheelProp) => {
    const { ageGender, region } = useWorldPoverty();

    const data = [
        { name: "In Poverty", value: headcount },
        { name: "Not In Poverty", value: totalPopulation! - headcount! }
    ];

    return (
        <div tw="relative left-0 h-[27rem] w-[27rem] sm:w-screen sm:h-[25rem] sm:left-[0rem] sm:top-[0rem]">
            {region !== "overall" ||
            ageGender.gender !== "overall" ||
            ageGender.age !== ageGroups[0] ? (
                <div tw="relative block top-0 left-0 w-[22rem] h-[22rem] sm:flex sm:flex-row sm:justify-center sm:items-center sm:w-screen">
                    <div tw="relative block top-12 left-10 sm:top-0 sm:left-0 border-gray-600 border-2 rounded-full w-[22rem] h-[22rem]">
                        <ResponsiveContainer height="100%" width="100%">
                            <PieChart>
                                <Pie
                                    dataKey="value"
                                    data={data}
                                    outerRadius={160}
                                    startAngle={30}
                                    endAngle={390}
                                    isAnimationActive={true}
                                    animationDuration={3000}
                                    animationEasing="linear"
                                    stroke="none"
                                    style={{
                                        filter: `drop-shadow(0px 0px 4px black)`
                                    }}
                                >
                                    {data.map((_, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                radialColorsForFilters[
                                                    index %
                                                        radialColorsForFilters.length
                                                ]
                                            }
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ) : (
                <>
                    <ResponsiveContainer height="100%" width="100%">
                        <PieChart>
                            <Pie
                                dataKey="value"
                                data={data}
                                outerRadius={180}
                                innerRadius={120}
                                startAngle={30}
                                endAngle={390}
                                isAnimationActive={true}
                                animationDuration={3000}
                                animationEasing="linear"
                            >
                                {data.map((_, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={
                                            radialColors[
                                                index % radialColors.length
                                            ]
                                        }
                                    />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </>
            )}
        </div>
    );
};
