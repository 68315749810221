import { CountryNamesTickerProps } from "interfaces/herosection.interface";
import { useHasFocus } from "utils/DocumentFocus";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const CountryNamesTicker = ({
    countryEscaping,
    countryIntoPov
}: CountryNamesTickerProps) => {
    const hasFocus = useHasFocus();
    return hasFocus ? (
        <>
            <div tw="absolute top-[25rem] right-[3rem] w-[10rem] text-[1vw] flex flex-col items-end text-right lg:hidden md:hidden sm:hidden">
                {countryEscaping}
            </div>
            <div tw="absolute top-[25.5rem] left-[3rem] w-[10rem] text-[1vw] flex flex-col items-start text-map-red lg:hidden md:hidden sm:hidden">
                {countryIntoPov}
            </div>
        </>
    ) : null;
};
