import { manRun, redManRun, redWomanRun, womanRun } from "assets/animations";
import { useWorldPoverty } from "context/worldPoverty";
import { PersonShape, TimerQueueItem } from "interfaces/person.interface";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { dailySpendingValues, heroReducerOptions } from "utils/Groups";
import { personSpeed } from "utils/Timing";
import {
    currentDate,
    getSecFromMidYear,
    getTodayInSec
} from "utils/CalculateTime";
import { heroSectionReducer } from "reducers/heroSectionState";
import {
    sortCountriesIntoPov,
    sortCountriesOnEscape
} from "utils/SortCountries";
import { HeroSectionState } from "interfaces/herosection.interface";
import { useHasFocus } from "utils/DocumentFocus";

const heroSectionDataInit: HeroSectionState = {
    headcount: 0,
    currEscapeFromPovRate: 0.2,
    currFellIntoPovRate: 0.2,
    currEscapeRate: 0.2,
    targetEscapeRate: 0.2,
    escapedToday: 0,
    fellIntoPovToday: 0,
    offTrack: 0,
    totalPopulation: 0,
    sortedEscapeCountries: [],
    sortedIntoPovCountries: []
};

const useHeroTimer = () => {
    const { ageGender, year, daily_spending, main_query_data } =
        useWorldPoverty();
    const idRef = useRef(0);
    const getId = () => idRef.current++;
    const [countryIntoPov, setCountryIntoPov] = useState<string>("");
    const [countryEscaping, setCountryEscaping] = useState<string>("");
    let povQueueSize = useRef(0);
    let escapeQueueSize = useRef(0);
    const [timerQueue, setTimerQueue] = useState<TimerQueueItem[]>([]);
    const endYear = 2031;
    const hasFocus = useHasFocus();
    const getGender = useCallback(
        () =>
            ageGender.gender === "overall"
                ? Math.random() >= 0.5
                    ? "male"
                    : "female"
                : ageGender.gender === "females"
                ? "female"
                : "male",
        [ageGender.gender]
    );

    const [heroSectionData, heroSectionDispatch] = useReducer(
        heroSectionReducer,
        heroSectionDataInit
    );

    useEffect(() => {
        const date = new Date(
            year,
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes(),
            currentDate.getSeconds()
        );
        if (main_query_data && main_query_data.worldData.finalData) {
            heroSectionDispatch({
                type: heroReducerOptions.set,
                payload: {
                    currEscapeRate:
                        main_query_data.worldData.finalData.currEscapeRate,
                    currEscapeFromPovRate:
                        main_query_data.worldData.finalData
                            .escapeFromPovertyRate,
                    currFellIntoPovRate:
                        main_query_data.worldData.finalData.fellIntoPovertyRate,
                    targetEscapeRate:
                        main_query_data.worldData.finalData.targetEscapeRate,
                    headcount: Math.round(
                        main_query_data.worldData.finalData.headcount -
                            getSecFromMidYear(date) *
                                main_query_data.worldData.finalData
                                    .currEscapeRate
                    ),
                    totalPopulation:
                        main_query_data.worldData.finalData.totalPopulation,
                    escapedToday: Math.round(
                        getTodayInSec() *
                            main_query_data.worldData.finalData
                                .escapeFromPovertyRate
                    ),
                    fellIntoPovToday: Math.round(
                        getTodayInSec() *
                            Math.abs(
                                main_query_data.worldData.finalData
                                    .fellIntoPovertyRate
                            )
                    ),
                    offTrack: Math.round(
                        main_query_data.worldData.finalData.sdgHeadcount -
                            (main_query_data.worldData.finalData
                                .targetEscapeRate -
                                main_query_data.worldData.finalData
                                    .currEscapeRate) *
                                getSecFromMidYear(date)
                    ),
                    sortedEscapeCountries: sortCountriesOnEscape(
                        main_query_data.countryValues
                    ).slice(0, 15),
                    sortedIntoPovCountries: sortCountriesIntoPov(
                        main_query_data.countryValues
                    ).slice(0, 15)
                }
            });
        }
    }, [main_query_data, year]);

    const enqueue = useCallback(
        (id: number, person: PersonShape) => {
            if (hasFocus) {
                if (person.isEscaping) {
                    escapeQueueSize.current += 1;
                    heroSectionDispatch({
                        type: heroReducerOptions.escape
                    });
                } else {
                    povQueueSize.current += 1;
                }
                setTimerQueue((queue) =>
                    queue.concat({
                        id,
                        person
                    })
                );
            } else {
                if (person.isEscaping) {
                    heroSectionDispatch({
                        type: heroReducerOptions.escape
                    });
                } else {
                    heroSectionDispatch({
                        type: heroReducerOptions.into
                    });
                }
            }
        },
        [hasFocus]
    );

    const dequeue = (id: number, person: PersonShape) => {
        if (!person.isEscaping) {
            povQueueSize.current -= 1;
            heroSectionDispatch({
                type: heroReducerOptions.into
            });
            if (povQueueSize.current < 1) {
                setCountryIntoPov("");
            }
        } else {
            escapeQueueSize.current -= 1;
            if (escapeQueueSize.current < 1) {
                setCountryEscaping("");
            }
        }
        setTimerQueue((queue) => queue.filter((el) => el.id !== id));
    };

    useEffect(() => {
        const sdgCount = setTimeout(() => {
            heroSectionDispatch({
                type: heroReducerOptions.offtrack
            });
        }, 1000 / heroSectionData.targetEscapeRate);
        return () => {
            clearTimeout(sdgCount);
        };
    }, [heroSectionData.targetEscapeRate, year, heroSectionData.offTrack]);

    useEffect(() => {
        const escapeCountdown = setTimeout(() => {
            if (year !== endYear && daily_spending === dailySpendingValues[0]) {
                let newId = getId();
                let gender = getGender();

                const countryEscape =
                    heroSectionData.sortedEscapeCountries[
                        Math.floor(Math.random() * 15)
                    ];
                countryEscape &&
                    setCountryEscaping(countryEscape.properties.name);
                enqueue(newId, {
                    id: newId,
                    position: 0,
                    endPosition: 23,
                    country: countryEscape ? countryEscape.properties.name : "",
                    stop: false,
                    isEscaping: true,
                    speed: personSpeed,
                    image: gender === "male" ? manRun : womanRun,
                    fade: false,
                    gender: gender,
                    flag:
                        countryEscape &&
                        countryEscape.flag &&
                        countryEscape.flag.file_url
                            ? countryEscape.flag.file_url
                            : ""
                });
            }
        }, (1 / heroSectionData.currEscapeFromPovRate) * 1000);
        return () => {
            clearTimeout(escapeCountdown);
        };
    }, [
        heroSectionData.escapedToday,
        heroSectionData.currEscapeFromPovRate,
        heroSectionData.sortedEscapeCountries,
        getGender,
        enqueue,
        year,
        daily_spending
    ]);

    useEffect(() => {
        const escapeCountup = setTimeout(() => {
            if (year !== endYear && daily_spending === dailySpendingValues[0]) {
                let newId = getId();
                let gender = getGender();

                const countryPov =
                    heroSectionData.sortedIntoPovCountries[
                        Math.floor(Math.random() * 15)
                    ];
                countryPov && setCountryIntoPov(countryPov.properties.name);
                enqueue(newId, {
                    id: newId,
                    position: 0,
                    country: countryPov ? countryPov.properties.name : "",
                    endPosition: 20,
                    stop: false,
                    isEscaping: false,
                    speed: 0.25,
                    image: gender === "male" ? redManRun : redWomanRun,
                    fade: false,
                    gender: gender,
                    flag:
                        countryPov &&
                        countryPov.flag &&
                        countryPov.flag.file_url
                            ? countryPov.flag.file_url
                            : ""
                });
            }
        }, (1 / Math.abs(heroSectionData.currFellIntoPovRate)) * 1000);
        return () => {
            clearTimeout(escapeCountup);
        };
    }, [
        heroSectionData.fellIntoPovToday,
        heroSectionData.currFellIntoPovRate,
        heroSectionData.sortedIntoPovCountries,
        getGender,
        enqueue,
        year,
        daily_spending
    ]);

    return {
        countryEscaping,
        countryIntoPov,
        heroSectionData,
        timerQueue,
        dequeue
    };
};

export default useHeroTimer;
