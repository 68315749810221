import {
    AgeGenderData,
    RegionData
} from "interfaces/world-poverty-context.interface";

export const handleReduceDataIds = (
    array: any[],
    handleId: (elem: any) => string
) => {
    return array.reduce((obj: any, item: any) => {
        obj[handleId(item)] = item;
        return obj;
    }, {});
};

export const handleFinalData = (
    gender: string,
    region: string,
    ageGenderValues?: AgeGenderData,
    regionValues?: RegionData
) => {
    if (region === "overall" && ageGenderValues) {
        switch (gender) {
            case "females":
                return ageGenderValues.females;
            case "males":
                return ageGenderValues.males;
            default:
                return ageGenderValues.overall;
        }
    } else if (regionValues) {
        switch (region) {
            case "rural":
                return regionValues.rural;
            case "urban":
                return regionValues.urban;
            default:
                return regionValues.overall;
        }
    }
};
