import { CountryDataShape } from "interfaces/world-poverty-context.interface";

export const sortCountriesOnEscape = (
    countryList: CountryDataShape[]
): CountryDataShape[] => {
    return countryList
        .filter((x) => x.finalData)
        .sort(function (a, b) {
            if (!a.finalData || !b.finalData) return 0;

            if (
                a.finalData.escapeFromPovertyRate <
                b.finalData.escapeFromPovertyRate
            ) {
                return 1;
            } else if (
                a.finalData.escapeFromPovertyRate >
                b.finalData.escapeFromPovertyRate
            ) {
                return -1;
            }
            return 0;
        });
};

export const sortCountriesIntoPov = (
    countryList: CountryDataShape[]
): CountryDataShape[] => {
    return countryList
        .filter((x) => x.finalData)
        .sort(function (a, b) {
            if (!a.finalData || !b.finalData) return 0;

            if (
                a.finalData.fellIntoPovertyRate >
                b.finalData.fellIntoPovertyRate
            ) {
                return 1;
            } else if (
                a.finalData.fellIntoPovertyRate <
                b.finalData.fellIntoPovertyRate
            ) {
                return -1;
            }
            return 0;
        });
};
