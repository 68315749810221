import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Close from "assets/images/close.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import CurrentEscape from "assets/images/icon-current-escape.svg";
import TargetEscape from "assets/images/icon-target-escape.svg";
import Filters from "assets/images/filters.svg";
import { CountryCard } from "interfaces/country.interface";
import {
  ContinentDataShape,
  CountryDataShape,
} from "interfaces/world-poverty-context.interface";
import NumbersStringify from "utils/NumbersStringify";
import { FullCardLong, FullCardRegular, CharLimit } from "utils/Styling";
import {
  dailySpendingValues,
  povertyThresholds,
  NONEOPTION,
  ALLOPTION,
} from "utils/Groups";
import { regionStyle } from "utils/Map";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const FullCountryCard = ({ stateRef, shiftCallback }: CountryCard) => {
  const {
    setActiveFilters,
    setFullModal,
    main_query_data,
    country,
    county,
    countryData,
    continentData,
    countyData,
    daily_spending,
    region,
    mapView,
  } = useWorldPoverty();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [cardData, setCardData] = useState<
    CountryDataShape | ContinentDataShape | null
  >(null);
  const latestCardDataRef = useRef<
    CountryDataShape | ContinentDataShape | null
  >(null);
  const [mapLevel, setMapLevel] = useState("country");

  useEffect(() => {
    const cardData: CountryDataShape | ContinentDataShape | null = continentData
      ? continentData
      : countyData
      ? countyData
      : countryData;

    latestCardDataRef.current = cardData;
    setCardData(cardData);
  }, [countyData, countryData, continentData]);

  let stableTrackColor =
    main_query_data && cardData
      ? regionStyle(
          cardData as CountryDataShape,
          country,
          county !== NONEOPTION
            ? countryData!.finalData!.headcount
            : main_query_data.worldData.finalData!.headcount,
          mapView
        )
      : {
          fillColor: "",
          fillOpacity: "",
          color: "",
          opacity: "",
        };
  stableTrackColor["color"] = stableTrackColor["fillColor"];

  useEffect(() => {
    if (continentData) {
      setMapLevel("continent");
    } else if (countyData) {
      setMapLevel("county");
    } else {
      setMapLevel("country");
    }
  }, [countyData, countryData, continentData]);

  const calcPercentInPov =
    cardData && cardData.finalData
      ? (cardData.finalData.headcount / cardData.finalData.totalPopulation) *
        100
      : null;

  return (
    <>
      {stateRef && <div
        tw="z-[300] sm:z-[999] flex flex-row sm:flex-col m-0 px-10 py-6 bg-black/90 absolute sm:fixed -right-20 bottom-[-15%] top-[-20%] sm:right-0 sm:bottom-0 sm:top-0 w-[42%] sm:w-[100%] text-white transition-all ease-in-out delay-500 duration-700 sm:overflow-scroll scale-[0.8] sm:scale-[1] min-w-[450px] sm:min-w-0 min-h-[500px]"
        css={[
          isExpanded && tw`w-[60%] min-w-[650px] sm:w-[100%] sm:min-w-0`,
          cardData ? tw`visible` : tw`hidden`,
        ]}>
        <div
          tw="flex flex-col w-full transition-all ease-in-out delay-500 duration-700"
          css={[isExpanded && tw`w-[50%] sm:w-[100%]`]}>
          <button className="hs-cta-trigger-button hs-cta-trigger-button-75725267674">
            <div tw="uppercase text-white text-sm tracking-[1px] bg-gray-500/100 py-2 w-36 sm:hidden">
              {t("request_data")}
            </div>
          </button>
          {cardData && (
            <div tw="flex flex-col divide-y-2 divide-gray-600">
              <div tw="flex flex-row items-center">
                <span
                  tw="object-contain h-4 rounded-full pr-4"
                  css={{
                    ...stableTrackColor,
                    backgroundColor: stableTrackColor["color"],
                  }}
                />
                {countryData && countryData.flag && (
                  <img
                    src={countryData.flag.file_url}
                    alt="Flag"
                    tw="w-10 h-8 mx-2"
                  />
                )}
                <p
                  tw="font-berlingske capitalize"
                  css={
                    cardData.properties.name.length > CharLimit
                      ? FullCardLong
                      : FullCardRegular
                  }>
                  {cardData.properties.name}
                </p>
              </div>
              <div tw="flex flex-row items-center justify-between py-3">
                <p tw="text-xs sm:text-sm text-gray-400">
                  {t("total_population")}
                </p>
                <p tw="text-white sm:text-[20px] font-workSansBold">
                  {cardData.finalData
                    ? NumbersStringify(cardData.finalData.totalPopulation)
                    : "No Data Available"}
                </p>
              </div>
              <div tw="flex flex-col py-3">
                <p tw="uppercase text-xs text-gray-400 sm:hidden">
                  {t("number_of_people_living_in")}
                  <span tw="whitespace-pre"> </span>
                  {t(
                    povertyThresholds[
                      dailySpendingValues.findIndex(
                        (item) => item === daily_spending
                      )
                    ]
                  )}
                </p>
                <div tw="flex flex-row items-center justify-between divide-x-2 divide-gray-600 py-2">
                  <div
                    tw="sm:flex sm:flex-row sm:justify-between sm:items-center sm:w-[100%] sm:pr-2"
                    css={stableTrackColor}>
                    <div tw="hidden sm:block w-[50%]">
                      <p tw="uppercase text-sm text-gray-400">
                        {t("people_living_in_extreme_poverty")}
                      </p>
                    </div>
                    <div>
                      <p tw="text-[28px] sm:text-[20px] font-bold font-workSansBold">
                        {cardData.finalData
                          ? NumbersStringify(cardData.finalData.headcount)
                          : t("no_data")}
                      </p>
                    </div>
                  </div>
                  <div
                    tw="px-2 text-[28px] sm:text-[20px] sm:py-2 font-bold font-berlingske"
                    css={stableTrackColor}>
                    <p>
                      {cardData.finalData
                        ? NumbersStringify(
                            Math.round(
                              (cardData.finalData.headcount /
                                cardData.finalData.totalPopulation) *
                                100
                            )
                          )
                        : t("no_data")}
                      %
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {cardData && (
            <div tw="flex flex-row">
              <span
                css={[
                  {
                    background: `conic-gradient(from 0deg, #EE4322 0% ${calcPercentInPov}%, #F9C6BC ${calcPercentInPov}% 100%)`,
                  },
                  tw`block max-h-[7rem] object-contain min-w-[7rem] rounded-full border-white border-2 sm:w-full sm:hidden`,
                  isExpanded && tw`min-h-[6rem] max-w-[6rem] pt-10`,
                ]}
              />
              <div
                tw="flex flex-col mx-4 sm:mx-0"
                css={[isExpanded && tw`scale-[90%]`]}>
                <div tw="flex flex-row items-center pt-4">
                  <img src={TargetEscape} alt="Target Escape Rate" />
                  <div tw="flex flex-col text-white text-xs sm:text-regular pl-2 sm:pl-4">
                    <p tw="uppercase tracking-[2px]">
                      {t("target_escape_rate")}
                    </p>
                    <p tw="font-workSansBold sm:pt-1">
                      {cardData.finalData &&
                        (cardData.finalData.targetEscapeRate * 60).toFixed(2)}
                      <span tw="whitespace-pre"> </span>
                      {t("people_per_min")}
                    </p>
                  </div>
                </div>

                <div tw="flex flex-row items-center pt-4">
                  <img src={CurrentEscape} alt="Current Escape Rate" />
                  <div tw="text-white pl-2 sm:pl-4 text-xs sm:text-regular">
                    <p tw="uppercase tracking-[2px]">
                      {t("current_escape_rate")}
                    </p>
                    <p tw="font-workSansBold sm:pt-1">
                      {cardData.finalData &&
                        (cardData.finalData.currEscapeRate * 60).toFixed(2)}
                      <span tw="whitespace-pre" />
                      {t("people_per_min")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          tw="tracking-[1px] invisible w-[0px] px-[0px] py-20 transition-all ease-in-out delay-500 duration-700 h-full flex flex-col sm:hidden sm:pt-12 sm:pb-4"
          css={[
            isExpanded &&
              tw`visible w-[55%] pr-12 px-8 sm:block sm:w-[100%] sm:px-0`,
          ]}>
          <div
            tw="flex flex-col h-full w-full"
            css={[
              isExpanded
                ? tw`opacity-100 transition-opacity ease-in delay-1000 duration-200`
                : tw`opacity-0 transition-opacity ease-in delay-200 duration-200`,
            ]}>
            <div tw="flex flex-col h-full w-full">
              <p>{t("poverty_thresholds.title")}</p>
              <div tw="flex flex-row">
                <button
                  tw="bg-gray-500 text-gray-600 px-4 py-1 sm:w-[33.3%]"
                  onClick={() => {
                    setActiveFilters({
                      name: "daily_spending",
                      value: dailySpendingValues[0],
                    });
                    setActiveFilters({
                      name: "region",
                      value: "overall",
                    });
                  }}
                  css={[
                    daily_spending === dailySpendingValues[0] &&
                      tw`bg-map-red font-bold text-white`,
                  ]}
                  disabled={daily_spending === dailySpendingValues[0]}>
                  {dailySpendingValues[0]}
                  <span tw="whitespace-pre"> </span>$
                </button>
                <button
                  tw="hidden bg-gray-500 text-gray-600 px-4 py-1 border-l border-r border-gray-400 sm:w-[33.3%]"
                  css={[
                    region === "overall" && tw`block`,
                    daily_spending === dailySpendingValues[1] &&
                      tw`bg-map-red font-bold text-white`,
                  ]}
                  onClick={() => {
                    setActiveFilters({
                      name: "daily_spending",
                      value: dailySpendingValues[1],
                    });
                    setActiveFilters({
                      name: "region",
                      value: "overall",
                    });
                  }}
                  disabled={daily_spending === dailySpendingValues[1]}>
                  {dailySpendingValues[1]}
                  <span tw="whitespace-pre"> </span>$
                </button>
                <button
                  tw="hidden bg-gray-500 text-gray-600 px-4 py-1 sm:w-[33.3%]"
                  css={[
                    region === "overall" && tw`block`,
                    daily_spending === dailySpendingValues[2] &&
                      tw`bg-map-red font-bold text-white`,
                  ]}
                  onClick={() => {
                    setActiveFilters({
                      name: "daily_spending",
                      value: dailySpendingValues[2],
                    });
                    setActiveFilters({
                      name: "region",
                      value: "overall",
                    });
                  }}
                  disabled={daily_spending === dailySpendingValues[2]}>
                  {dailySpendingValues[2]}
                  <span tw="whitespace-pre"> </span>$
                </button>
              </div>
            </div>
            {mapLevel !== "county" && cardData && cardData.ageGenderData ? (
              <div tw="flex flex-col w-full divide-y">
                <div tw="py-2">
                  <div tw="flex flex-row w-full divide-x items-center p-1">
                    <div tw="uppercase flex flex-row flex-grow items-center justify-between pr-4">
                      <p tw="text-gray-400 text-xs sm:w-[50%]">
                        {t("in_rural_areas")}
                      </p>
                      <p tw="font-bold pl-2">
                        {daily_spending === dailySpendingValues[0] &&
                        cardData.regionData
                          ? NumbersStringify(
                              cardData.regionData.value.rural.headcount
                            )
                          : cardData.finalData
                          ? "-"
                          : t("no_data")}
                      </p>
                    </div>
                    <div tw="pl-8 pr-7 w-10 flex flex-row items-end">
                      <p tw="font-berlingske">
                        {daily_spending === dailySpendingValues[0] &&
                        cardData.regionData
                          ? Math.round(
                              (cardData.regionData.value.rural.headcount /
                                cardData.regionData.value.overall.headcount) *
                                100
                            ) + "%"
                          : cardData.finalData
                          ? "-"
                          : t("no_data")}
                      </p>
                    </div>
                  </div>
                  <div tw="flex flex-row w-full divide-x items-center p-1">
                    <div tw="uppercase flex flex-row flex-grow items-center justify-between pr-4">
                      <p tw="text-gray-400 text-xs sm:w-[50%]">
                        {t("in_urban_areas")}
                      </p>
                      <p tw="font-bold pl-2">
                        {daily_spending === dailySpendingValues[0] &&
                        cardData.regionData
                          ? NumbersStringify(
                              cardData.regionData.value.urban.headcount
                            )
                          : cardData.finalData
                          ? "-"
                          : t("no_data")}
                      </p>
                    </div>
                    <div tw="pl-8 pr-7 w-10 flex flex-row items-end">
                      <p tw="font-berlingske">
                        {daily_spending === dailySpendingValues[0] &&
                        cardData.regionData
                          ? Math.round(
                              (cardData.regionData.value.urban.headcount /
                                cardData.regionData.value.overall.headcount) *
                                100
                            ) + "%"
                          : cardData.finalData
                          ? "-"
                          : t("no_data")}
                      </p>
                    </div>
                  </div>
                </div>
                <div tw="py-2">
                  <div tw="flex flex-row w-full divide-x items-center p-1">
                    <div tw="uppercase flex flex-row flex-grow items-center justify-between pr-2">
                      <p tw="text-gray-400 text-xs">{t("females")}</p>
                      <p tw="font-bold pl-2">
                        {cardData.finalData
                          ? NumbersStringify(
                              cardData.ageGenderData!.value.females.headcount
                            )
                          : t("no_data")}
                      </p>
                    </div>
                    <div tw="pl-8 pr-7 w-10 flex flex-row items-end">
                      <p tw="font-berlingske">
                        {cardData.finalData
                          ? Math.round(
                              (cardData.ageGenderData!.value.females.headcount /
                                cardData.ageGenderData!.value.overall
                                  .headcount) *
                                100
                            )
                          : t("no_data")}
                        %
                      </p>
                    </div>
                  </div>
                  <div tw="flex flex-row w-full divide-x items-center p-1">
                    <div tw="uppercase flex flex-row flex-grow items-center justify-between pr-4">
                      <p tw="text-gray-400 text-xs">{t("males")}</p>
                      <p tw="font-bold pl-2">
                        {cardData.finalData
                          ? NumbersStringify(
                              cardData.ageGenderData!.value.males.headcount
                            )
                          : t("no_data")}
                      </p>
                    </div>
                    <div tw="pl-8 pr-7 w-10 flex flex-row items-end">
                      <p tw="font-berlingske">
                        {cardData.finalData
                          ? Math.round(
                              (cardData.ageGenderData!.value.males.headcount /
                                cardData.ageGenderData!.value.overall
                                  .headcount) *
                                100
                            )
                          : t("no_data")}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <button
          tw="absolute h-4 w-4 top-4 right-8"
          onClick={() => {
            stateRef.current = false;
            setActiveFilters({
              name:
                country === NONEOPTION
                  ? "continent"
                  : county !== NONEOPTION
                  ? "county"
                  : "country",
              value: ALLOPTION,
            });
          }}>
          <img src={Close} alt="Close" />
        </button>
        <div tw="sm:flex sm:flex-row sm:justify-between sm:py-8 sm:items-center">
          <button
            tw="hidden capitalize sm:flex sm:flex-row items-center font-bold bg-map-red rounded-full px-4 py-2 justify-center"
            onClick={() => {
              stateRef.current = false;
              setFullModal({
                name: "fullModal",
                value: true,
                section: "mobile_filters",
              });
            }}>
            <img src={Filters} alt="Filters" />
            {t("filters")}
          </button>
          <button
            tw="absolute bottom-12 right-4 border border-gray-400 rounded-full px-4 flex flex-row items-center sm:static sm:justify-center sm:h-6"
            onClick={() => {
              shiftCallback(isExpanded);
              setIsExpanded(!isExpanded);
            }}>
            {isExpanded ? t("hide_details") : t("show_details")}
            <img
              src={LeftArrow}
              alt="Arrow"
              tw="w-4 ml-2 sm:-rotate-90"
              css={[isExpanded && tw`rotate-180 sm:rotate-90`]}
            />
          </button>
        </div>

        <p tw="text-gray-600 text-[10px] absolute bottom-5 sm:static sm:text-gray-400 sm:text-[12px]">
          {t("based_on_wdl_model")}
          <span tw="whitespace-pre"> </span>
          <a
            tw="font-bold text-map-red"
            href="https://www.nature.com/articles/s41599-018-0083-y">
            {t("nature")}
          </a>
        </p>
      </div>}
    </>
  );
};

export default FullCountryCard;

