import { useState, useEffect } from "react";
import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Map from "components/Map";
import {
    stableTracks,
    mapOptions,
    countyMapOptions,
    NONEOPTION
} from "utils/Groups";
import { mapPercents } from "utils/Map";
import Lense from "assets/images/lens.svg";
import Filters from "assets/images/filters.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme as twTheme } from "twin.macro";
import { forwardRef } from "react";

const MapSection = forwardRef<HTMLDivElement | null>((props, ref) => {
    const { mapView, setActiveFilters, setFullModal, county, continent } =
        useWorldPoverty();
    const [isMobile, setIsMobile] = useState<boolean>(
        window.matchMedia(`(max-width: ${twTheme`screens.sm.max`})`).matches
    );

    function handleWindowSizeChange() {
        setIsMobile(
            window.matchMedia(`(max-width: ${twTheme`screens.sm.max`})`).matches
        );
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <section
            id="map-section"
            ref={ref}
            tw="relative flex flex-col items-center mt-8"
        >
            <div tw="flex flex-col justify-center items-center w-full">
                <div tw="sm:w-[100%] w-[80%] ">
                    <Map />
                </div>

                <div tw="w-full">
                    {mapView === mapOptions[0] ? (
                        <div tw="flex flex-row sm:flex-wrap justify-center pt-2 text-[1.05vw] sm:text-[16px]">
                            {stableTracks.map((track) => (
                                <div
                                    tw="px-4 flex flex-row items-center"
                                    key={track.type}
                                    css={[isMobile && track.color, tw`m-2`]}
                                >
                                    <div
                                        css={[track.color]}
                                        tw="sm:hidden w-4 h-4 rounded-full mx-2"
                                    >
                                        {" "}
                                    </div>
                                    <p tw="sm:block hidden lowercase">
                                        {t(track.type)}
                                    </p>
                                    <p tw="sm:hidden block capitalize">
                                        {t(track.value)}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div tw="flex flex-row justify-center pt-6">
                            <p tw="">{mapPercents[mapView].start}%</p>
                            <span tw="w-[50%] bg-gradient-to-r from-gray-200 to-map-red rounded-full mx-2" />
                            <p tw="">{mapPercents[mapView].end}%</p>
                        </div>
                    )}
                </div>

                <div tw="flex flex-row justify-center">
                    <div tw="hidden text-white sm:flex sm:flex-row pb-4">
                        <button
                            tw="bg-map-red py-1 px-4 m-2 rounded-full flex flex-row items-center"
                            onClick={() =>
                                setFullModal({
                                    name: "fullModal",
                                    value: true,
                                    section: "mobile_filters"
                                })
                            }
                        >
                            <img src={Filters} alt="Filters" tw="p-1" />
                            <p tw="font-bold capitalize">{t("filters")}</p>
                        </button>
                        <button
                            tw="bg-map-red py-1 px-4 m-2 rounded-full flex flex-row items-center"
                            onClick={() =>
                                setFullModal({
                                    name: "fullModal",
                                    value: true,
                                    section: "mobile_lenses"
                                })
                            }
                        >
                            <img src={Lense} alt="Lenses" tw="h-8 w-8 p-1" />
                            <p tw="font-bold capitalize">{t("lenses")}</p>
                        </button>
                    </div>
                    <div tw="block sm:hidden text-[1.05vw]">
                        {(county !== NONEOPTION
                            ? countyMapOptions
                            : mapOptions
                        ).map((option) => (
                            <button
                                tw="bg-white rounded-full m-3 px-6 border-2 border-gray-400"
                                key={option}
                                disabled={continent !== NONEOPTION}
                                css={[
                                    mapView === option &&
                                        tw`bg-map-red text-white font-bold border-2 border-map-red`
                                ]}
                                onClick={() => {
                                    setActiveFilters({
                                        name: "mapView",
                                        value: option
                                    });
                                }}
                            >
                                {t(option)}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
});

export default MapSection;
