import { AgeGroup } from "interfaces/world-poverty-context.interface";
import { ageGroups } from "utils/Groups";

export const ageStringify = (AgeGroup: AgeGroup): string => {
    if (AgeGroup.group.from === 0 && !AgeGroup.group.to) {
        return ageGroups[0].name;
    }

    return `${AgeGroup.group.from}${
        AgeGroup.group.to ? `-${AgeGroup.group.to}` : "+"
    }`;
};
