import { useRef, useState } from "react";
import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Tick from "assets/images/tick.svg";
import ArrowDown from "assets/images/dropdown.svg";
import Dropdown from "components/Filters/Dropdown";
import { ageGroups } from "utils/Groups";
import { ageStringify } from "utils/AgeStringify";
import { FilterProps } from "interfaces/filter-props.interface";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Filter = ({
    filterInfo,
    setShowAgeGenderInfo,
    showAgeGenderInfo,
    setShowRegionInfo,
    showRegionInfo
}: FilterProps) => {
    const { ageGender, region, county, setActiveFilters } = useWorldPoverty();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const ageButtonRef = useRef<HTMLDivElement | null>(null);

    const handleShowInfoClick = (type: String) => {
        if (type === "region") {
            setShowAgeGenderInfo(false);
            setShowRegionInfo(true);
        } else if (type === "ageGender") {
            setShowRegionInfo(false);
            setShowAgeGenderInfo(true);
        }
    };

    const handleFilterClick = (f: String) => {
        if (f === ageGroups[0].name) {
            setIsDropdownOpen(!isDropdownOpen);
        } else if (filterInfo.name === "region") {
            if (region === "overall") {
                handleShowInfoClick("region");
            }
            setActiveFilters({
                name: "region",
                value: f
            });
            setActiveFilters({
                name: "ageGender",
                value: {
                    age: ageGroups[0],
                    gender: "overall"
                }
            });
        } else {
            if (ageGender.gender === "overall") {
                handleShowInfoClick("ageGender");
            }
            setActiveFilters({
                name: "ageGender",
                value: {
                    age: ageGender.age,
                    gender: f
                }
            });
            setActiveFilters({
                name: "region",
                value: "overall"
            });
        }
    };

    const closeDropdown = ({ target }: MouseEvent) => {
        if (
            dropdownRef.current &&
            ageButtonRef.current &&
            isDropdownOpen &&
            !dropdownRef.current.contains(target as Node) &&
            !ageButtonRef.current.contains(target as Node)
        ) {
            setIsDropdownOpen(false);
        }
    };

    document.addEventListener("mousedown", closeDropdown);

    const activeStyling = (filter: string) => {
        if (county !== "none") {
            return tw`bg-gray-400 border-gray-400 text-gray-500 hover:cursor-default`;
        }

        if (filterInfo.name === "region") {
            switch (region) {
                case "rural":
                    return filter === "rural"
                        ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                        : tw`bg-white border-2 border-map-red`;
                case "urban":
                    return filter === "urban"
                        ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                        : tw`bg-white border-2 border-map-red`;
                case "overall":
                    return tw`bg-map-lightRed border-2 border-map-lightRed`;
                default:
                    return tw``;
            }
        } else {
            if (filter === ageGroups[0].name) {
                if (
                    ageGender.age.group.from === 0 &&
                    ageGender.age.group.to === null
                ) {
                    return ageGender.gender === "overall"
                        ? tw`bg-map-lightRed border-2 border-map-lightRed`
                        : tw`bg-white border-2 border-map-red`;
                } else {
                    return tw`bg-map-red text-white font-bold border-2 border-map-red`;
                }
            }

            switch (ageGender.gender) {
                case "females":
                    return filter === "females"
                        ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                        : tw`bg-white border-2 border-map-red`;
                case "males":
                    return filter === "males"
                        ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                        : tw`bg-white border-2 border-map-red`;
                case "overall":
                    return tw`bg-map-lightRed border-2 border-map-lightRed`;
                default:
                    return tw``;
            }
        }
    };

    return (
        <div tw="mx-2 p-2 lg:mx-1">
            <p tw="text-xs text-brown tracking-[3px] ml-2 lg:text-[0.5rem] md:w-10">
                {t(filterInfo.type)}
            </p>
            <div tw="flex flex-row justify-start">
                {filterInfo.filters.map((filter: string) => {
                    return (
                        <div
                            tw="static"
                            key={filter}
                            ref={
                                filter === ageGroups[0].name
                                    ? ageButtonRef
                                    : null
                            }
                        >
                            <button
                                tw="bg-map-red w-28 m-2 px-3 py-1 text-sm lowercase flex flex-row justify-between lg:w-20 lg:text-[0.7rem] lg:m-1 md:w-[4rem] md:text-[0.6rem] md:m-1 md:px-1 md:pl-2 items-center"
                                css={[activeStyling(filter)]}
                                key={filter}
                                onClick={() => handleFilterClick(filter)}
                                disabled={county !== "none"}
                            >
                                <p>
                                    {filter === ageGroups[0].name
                                        ? t(ageStringify(ageGender.age))
                                        : t(filter)}
                                </p>
                                <img
                                    src={
                                        filter === ageGroups[0].name
                                            ? ArrowDown
                                            : Tick
                                    }
                                    alt="Selected"
                                    css={[
                                        tw`invisible`,
                                        filterInfo.name === "region" &&
                                            region === filter &&
                                            tw`visible`,
                                        filterInfo.name === "ageGender" &&
                                            ageGender.gender === filter &&
                                            tw`visible`,
                                        filterInfo.name === "ageGender" &&
                                            ageGender.age !== ageGroups[0] &&
                                            filter !== "females" &&
                                            filter !== "males" &&
                                            tw`visible`
                                    ]}
                                />
                            </button>
                            <div tw="relative">
                                {filter === ageGroups[0].name &&
                                    isDropdownOpen && (
                                        <Dropdown dropdownRef={dropdownRef} />
                                    )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div
                tw="absolute"
                css={{
                    visibility:
                        showAgeGenderInfo || showRegionInfo
                            ? "visible"
                            : "hidden"
                }}
            >
                {filterInfo.name === "region" ? (
                    <div>
                        <div
                            css={[
                                showRegionInfo
                                    ? tw`transition-opacity opacity-100 duration-[600ms]`
                                    : tw`transition-opacity opacity-0 duration-[600ms]`
                            ]}
                        >
                            <div tw="mt-[0.4rem] bg-gray-400">
                                <p tw="ml-5 mr-5">
                                    {t("tooltip.rural_urban_selection")}
                                </p>
                            </div>
                            <div
                                css={{
                                    width: 0,
                                    height: 0,
                                    borderLeft: "0.7em solid transparent",
                                    borderRight: "0.7em solid transparent",
                                    borderBottom: "0.7em solid #C9C9C9"
                                }}
                                tw="absolute top-[-0.2rem] left-[1.5rem]"
                            ></div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div
                            css={[
                                showAgeGenderInfo
                                    ? tw`transition-all opacity-100 duration-[600ms]`
                                    : tw`transition-all opacity-0 duration-[600ms]`
                            ]}
                        >
                            <div tw="w-[80%] mt-[0.4rem] bg-gray-400">
                                <p tw="ml-5 mr-5">
                                    {t("tooltip.demographic_selection")}
                                </p>
                            </div>
                            <div
                                css={{
                                    width: 0,
                                    height: 0,
                                    borderLeft: "0.7em solid transparent",
                                    borderRight: "0.7em solid transparent",
                                    borderBottom: "0.7em solid #C9C9C9"
                                }}
                                tw="absolute top-[-0.2rem] left-[1.7rem]"
                            ></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Filter;
