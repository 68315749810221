import LeftArrow from "assets/images/left-arrow.svg";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

export const ScrollDown = () => {
    return (
        <div tw="self-center flex flex-row border-t border-gray-400 px-4 py-2 my-6 sm:hidden">
            <img
                src={LeftArrow}
                alt="arrow"
                tw="invert -rotate-90 w-4 h-4 mr-2"
            />
            <p tw="uppercase text-sm">{t("scroll_down_to_see_the_map")}</p>
        </div>
    );
};
