import { ALLOPTION } from "utils/Groups";

export const continentBounds = {
    NA: {
        bb0: -171.7911106028912,
        bb1: 7.220541490096537,
        bb2: -12.20855,
        bb3: 83.64513
    },
    SA: {
        bb0: -81.41094255239946,
        bb1: -55.61183,
        bb2: -34.729993455533034,
        bb3: 12.437303168177309
    },
    EU: {
        bb0: -24.542225,
        bb1: 31.78639,
        bb2: 50.37499,
        bb3: 70.088882
    },
    AS: {
        bb0: 26.04335127127254,
        bb1: -10.359987481327963,
        bb2: 145.54313724180278,
        bb3: 55.38525014914353
    },
    AF: {
        bb0: -17.62504269049066,
        bb1: -34.81916635512371,
        bb2: 51.13387,
        bb3: 37.349994411766545
    },
    OC: {
        bb0: 113.3389530782625,
        bb1: -46.641235446967855,
        bb2: 178.51709354076283,
        bb3: -2.500002129734028
    }
};

export const continentOptions = [
    {
        id: ALLOPTION,
        properties: {
            name: "All Continents"
        }
    },
    {
        id: "Africa",
        properties: {
            name: "Africa"
        }
    },
    {
        id: "Asia",
        properties: {
            name: "Asia"
        }
    },
    {
        id: "Europe",
        properties: {
            name: "Europe"
        }
    },
    {
        id: "North America",
        properties: {
            name: "North America"
        }
    },
    {
        id: "South America",
        properties: {
            name: "South America"
        }
    },
    {
        id: "Oceania",
        properties: {
            name: "Oceania"
        }
    }
];
