import { Actions } from "interfaces/global-state.interface";
import { GlobalState } from "interfaces/world-poverty-context.interface";

export const globalStateReducer = (
    state: GlobalState,
    { type, payload }: Actions
) => {
    switch (type) {
        case "SELECT_FILTERS":
            return {
                ...state,
                [payload.name]: payload.value
            };
        case "SELECT_MODAL":
            return {
                ...state,
                [payload.name]: {
                    ...state.fullModal,
                    isModalOpen:
                        payload.section === state.fullModal.section
                            ? payload.value
                            : true,
                    section: payload.section
                }
            };
        default:
            throw new Error();
    }
};
