import { default as ReactSelect, SingleValue } from "react-select";
import { useWorldPoverty } from "context/worldPoverty";
import { yearOptions } from "utils/Groups";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MobileYear = () => {
    const { year, setActiveFilters } = useWorldPoverty();
    // compatability with react-select
    const yearsList = yearOptions().map((year) => {
        return { name: `${year}`, value: year };
    });

    const onChange = (option: SingleValue<number>) => {
        setActiveFilters({
            name: "year",
            value: option
        });
    };

    return (
        <div tw="flex flex-row z-[502] fixed bottom-0 w-full">
            <ReactSelect
                tw="w-full border-b-[1px] border-black"
                menuPlacement={"top"}
                isSearchable={false}
                styles={{
                    control: (base) => ({
                        ...base,
                        border: 0,
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: "black",
                        paddingRight: "8px",
                        paddingTop: "8px",
                        paddingBottom: "8px"
                    }),
                    option: (base) => ({
                        ...base,
                        backgroundColor: "none",
                        borderBottom: "1px solid darkgray",
                        color: "gray",
                        fontFamily: "Work Sans",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        "&:hover": {
                            backgroundColor: "lightgray"
                        },
                        zIndex: 900
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: "white",
                        backgroundColor: "black",
                        paddingLeft: "8px"
                    }),
                    indicatorSeparator: (base) => ({
                        ...base,
                        display: "none"
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: "white"
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: "white"
                    })
                }}
                theme={(theme) => ({
                    ...theme,
                    border: 0
                })}
                options={yearsList}
                getOptionLabel={(option) => option.name}
                onChange={(selected) => {
                    selected && onChange(selected.value);
                }}
                placeholder={year}
            />
        </div>
    );
};

export default MobileYear;
