import Header from "components/Header";
import HeroSection from "components/Main/HeroSection";
import MapSection from "components/Main/MapSection";
import Bookmark from "components/Bookmark";
import YearSlider from "components/YearSlider";
import Modal from "components/Modal";
import Splash from "components/Splash";
import Footer from "components/Footer";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useRef } from "react";

const WorldPoverty = () => {
    const mapSectionRef = useRef<HTMLDivElement | null>(null);
    const heroSectionRef = useRef<HTMLDivElement | null>(null);
    return (
        <>
            <Header mapSectionRef={mapSectionRef} />
            <main>
                <HeroSection
                    mapSectionRef={mapSectionRef}
                    ref={heroSectionRef}
                />
                <MapSection ref={mapSectionRef} />
                <Bookmark
                    heroSectionRef={heroSectionRef}
                    mapSectionRef={mapSectionRef}
                />
            </main>
            <YearSlider />
            <Modal />
            <Splash />
            <Footer />
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js-eu1.hs-scripts.com/25336566.js"
            ></script>
        </>
    );
};

export default WorldPoverty;
