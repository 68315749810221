import IconTwitter from "assets/images/icon-twitter.png";
import IconFacebook from "assets/images/icon-facebook.png";
import IconLinkedin from "assets/images/icon-linkedin.png";
import IconWeb from "assets/images/icon-web.png";

export const socials = [
    {
        name: "Twitter",
        img: IconTwitter,
        href: "https://twitter.com/worlddatalab"
    },
    {
        name: "LinkedIn",
        img: IconLinkedin,
        href: "https://www.linkedin.com/company/world-data-lab/"
    },
    {
        name: "Facebook",
        img: IconFacebook,
        href: "https://www.facebook.com/worlddatalab"
    },
    {
        name: "World Data Lab",
        img: IconWeb,
        href: "https://www.worlddata.io"
    }
];
