export const currentDate = new Date();

export const getTodayInSec = () => {
    return (
        currentDate.getSeconds() +
        currentDate.getMinutes() * 60 +
        currentDate.getHours() * 3600
    );
};

export const getSecFromMidYear = (date: Date) => {
    const midYear = new Date(date.getFullYear(), 6, 2);
    const newYear = new Date(date.getFullYear(), 0, 1);
    return currentDate.getFullYear() === date.getFullYear()
        ? (newYear.getTime() -
              midYear.getTime() +
              (date.getTime() - newYear.getTime())) /
              1000
        : 0;
};
