import { t } from "i18next";
import closeImg from "assets/images/close.svg";
import leftArrow from "assets/images/left-arrow.svg";
import { useWorldPoverty } from "context/worldPoverty";
import { ModalProps } from "interfaces/modal-props.interface";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { closeClick } from "utils/Modal";

const Explained = ({ modal, modalText }: ModalProps) => {
    const { setFullModal } = useWorldPoverty();

    return (
        <>
            <button tw="fixed top-6 right-8">
                <img
                    src={closeImg}
                    alt="Close Explain"
                    tw="h-6 w-6"
                    onClick={() => setFullModal(closeClick(modal))}
                />
            </button>
            <div tw="flex flex-col w-[45%] sm:w-[85%] sm:items-center text-white">
                <div tw="flex flex-row ml-[30%] items-center sm:ml-0 sm:hidden">
                    <div tw="pr-4">
                        <div tw="flex justify-center items-center w-9 h-9 rounded-full border-2 border-white">
                            <button>
                                <img
                                    src={leftArrow}
                                    alt="Back"
                                    tw="h-6 w-6"
                                    onClick={() =>
                                        setFullModal(closeClick(modal))
                                    }
                                />
                            </button>
                        </div>
                    </div>
                    {t("back")}
                </div>
                <div tw="ml-[50%] sm:ml-0">
                    <h1 tw="text-xxl font-berlingske my-8">
                        {t(modalText.header)}
                    </h1>
                    <p>{t(modalText.body)}</p>
                </div>
            </div>
        </>
    );
};

export default Explained;
