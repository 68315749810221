interface rbg {
    r: number;
    b: number;
    g: number;
}

export const radialColorsForFilters = ["#EE4322", "#F9C6BC"];
export const radialColors = ["#EE4322", "black"];

export const HexToRGB = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
};

export const ColorInGradient = (
    startColor: rbg | null,
    endColor: rbg | null,
    percent: number
) => {
    return (
        endColor &&
        startColor && {
            r: Math.floor(startColor.r + percent * (endColor.r - startColor.r)),
            g: Math.floor(startColor.r + percent * (endColor.g - startColor.g)),
            b: Math.floor(startColor.r + percent * (endColor.b - startColor.b))
        }
    );
};
