import { useWorldPoverty } from "context/worldPoverty";
import closeImg from "assets/images/close.svg";
import Tick from "assets/images/tick.svg";
import ArrowDown from "assets/images/dropdown.svg";
import Logo from "assets/images/logo-half-white.svg";
import { ageGroups } from "utils/Groups";
import { useEffect, useRef, useState } from "react";
import { closeClick, modalTypes } from "utils/Modal";
import { NONEOPTION } from "utils/Groups";
import Dropdown from "components/Filters/Dropdown";
import YearDropdown from "./YearDropdown";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const FilterStylingInit = {
    male: tw`bg-map-lightRed border-2 border-map-lightRed`,
    female: tw`bg-map-lightRed border-2 border-map-lightRed`,
    urban: tw`bg-map-lightRed border-2 border-map-lightRed`,
    rural: tw`bg-map-lightRed border-2 border-map-lightRed`
};

const MobileFilter = () => {
    const {
        setActiveFilters,
        year,
        ageGender,
        region,
        fullModal,
        setFullModal
    } = useWorldPoverty();
    const [activeStyling, setActiveStyling] = useState(FilterStylingInit);
    const [isAgeDropdownOpen, setIsAgeDropdownOpen] = useState(false);
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const mobileAgeButtonRef = useRef<HTMLDivElement | null>(null);

    const handleFilterClick = (filterGroup: string, filterValue: string) => {
        if (filterGroup === "reset") {
            setActiveFilters({
                name: "region",
                value: "overall"
            });
            setActiveFilters({
                name: "ageGender",
                value: {
                    age: ageGroups[0],
                    gender: "overall"
                }
            });
        }
        if (filterGroup === "region") {
            setActiveFilters({
                name: filterGroup,
                value: filterValue
            });
            setActiveFilters({
                name: "ageGender",
                value: {
                    age: ageGroups[0],
                    gender: "overall"
                }
            });
        }
        if (filterGroup === "ageGender") {
            setActiveFilters({
                name: filterGroup,
                value: {
                    age: ageGender.age,
                    gender: filterValue
                }
            });
            setActiveFilters({
                name: "region",
                value: "overall"
            });
        }
    };

    const closeMobileDropdown = ({ target }: MouseEvent) => {
        if (
            dropdownRef.current &&
            mobileAgeButtonRef.current &&
            isAgeDropdownOpen &&
            !dropdownRef.current.contains(target as Node) &&
            !mobileAgeButtonRef.current.contains(target as Node)
        ) {
            setIsAgeDropdownOpen(false);
        }
    };

    document.addEventListener("mousedown", closeMobileDropdown);

    useEffect(() => {
        setActiveStyling({
            rural:
                region === "overall"
                    ? tw`bg-map-lightRed border-2 border-map-lightRed`
                    : region === "rural"
                    ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                    : tw`bg-white border-2 border-map-red`,
            urban:
                region === "overall"
                    ? tw`bg-map-lightRed border-2 border-map-lightRed`
                    : region === "rural"
                    ? tw`bg-white border-2 border-map-red`
                    : tw`bg-map-red text-white font-bold border-2 border-map-red`,
            male:
                ageGender.gender === "overall"
                    ? tw`bg-map-lightRed border-2 border-map-lightRed`
                    : ageGender.gender === "males"
                    ? tw`bg-map-red text-white font-bold border-2 border-map-red`
                    : tw`bg-white border-2 border-map-red`,
            female:
                ageGender.gender === "overall"
                    ? tw`bg-map-lightRed border-2 border-map-lightRed`
                    : ageGender.gender === "males"
                    ? tw`bg-white border-2 border-map-red`
                    : tw`bg-map-red text-white font-bold border-2 border-map-red`
        });
    }, [ageGender, region]);
    return (
        <>
            <div tw="fixed top-4 left-4">
                <img src={Logo} alt="Logo" tw="h-12 w-12" />
            </div>
            <button tw="fixed top-6 right-6">
                <img
                    src={closeImg}
                    alt="Close Modal"
                    tw="h-4 w-4"
                    onClick={() =>
                        setFullModal(closeClick(modalTypes.mobileFilters))
                    }
                />
            </button>
            <div tw="flex flex-col w-[90%] items-center">
                <div tw="flex flex-col w-full my-4">
                    <p tw="text-white my-2 mx-4">{t("geographical_filters")}</p>
                    <div tw="flex flex-row w-full justify-center my-2">
                        <button
                            tw="w-full mr-1 py-3"
                            css={[activeStyling.rural]}
                            onClick={() => handleFilterClick("region", "rural")}
                        >
                            <div tw="flex flex-row relative justify-center">
                                <p>{t("rural")}</p>
                                <img
                                    src={Tick}
                                    alt="Selected"
                                    tw="invisible absolute right-3 top-1.5 h-3 w-3"
                                    css={[
                                        region === "rural" &&
                                            fullModal.isModalOpen &&
                                            tw`visible`
                                    ]}
                                />
                            </div>
                        </button>
                        <button
                            tw="w-full ml-1 py-3"
                            css={[activeStyling.urban]}
                            onClick={() => handleFilterClick("region", "urban")}
                        >
                            <div tw="flex flex-row relative justify-center">
                                <p>{t("urban")}</p>
                                <img
                                    src={Tick}
                                    alt="Selected"
                                    tw="invisible absolute right-3 top-1.5 h-3 w-3"
                                    css={[
                                        region === "urban" &&
                                            fullModal.isModalOpen &&
                                            tw`visible`
                                    ]}
                                />
                            </div>
                        </button>
                    </div>
                </div>
                <div tw="flex flex-col w-full items-center my-4">
                    <p tw="text-white my-2">{t("demographic_filters")}</p>
                    <div tw="flex flex-row w-full justify-center my-2">
                        <button
                            tw="w-full mr-1 py-3"
                            css={[activeStyling.male]}
                            onClick={() =>
                                handleFilterClick("ageGender", "males")
                            }
                        >
                            <div tw="flex flex-row relative justify-center">
                                <p>{t("male")}</p>
                                <img
                                    src={Tick}
                                    alt="Selected"
                                    tw="invisible absolute right-3 top-1.5 h-3 w-3"
                                    css={[
                                        ageGender.gender === "males" &&
                                            fullModal.isModalOpen &&
                                            tw`visible`
                                    ]}
                                />
                            </div>
                        </button>
                        <button
                            tw="w-full ml-1 py-3"
                            css={[activeStyling.female]}
                            onClick={() =>
                                handleFilterClick("ageGender", "females")
                            }
                        >
                            <div tw="flex flex-row relative justify-center">
                                <p>{t("female")}</p>
                                <img
                                    src={Tick}
                                    alt="Selected"
                                    tw="invisible absolute right-3 top-1.5 h-3 w-3"
                                    css={[
                                        ageGender.gender === "females" &&
                                            fullModal.isModalOpen &&
                                            tw`visible`
                                    ]}
                                />
                            </div>
                        </button>
                    </div>
                    <div tw="flex flex-col w-full" ref={mobileAgeButtonRef}>
                        <button
                            tw="bg-map-lightRed w-full py-3 px-5"
                            onClick={() =>
                                setIsAgeDropdownOpen(!isAgeDropdownOpen)
                            }
                        >
                            <div tw="flex flex-row relative justify-start">
                                <p tw="pl-5">{t("ages")}</p>
                                {ageGender.age !== ageGroups[0] ? (
                                    <p>
                                        &nbsp; ({ageGender.age.group.from}-
                                        {ageGender.age.group.to})
                                    </p>
                                ) : (
                                    <></>
                                )}
                                <img
                                    src={ArrowDown}
                                    alt="Selected"
                                    tw="absolute right-2 top-1.5 h-3 w-3"
                                />
                            </div>
                        </button>
                        <div tw="relative">
                            {isAgeDropdownOpen && (
                                <Dropdown dropdownRef={dropdownRef} />
                            )}
                        </div>
                    </div>
                </div>

                <div tw="flex flex-col w-full mb-4 mt-16">
                    <p tw="text-white uppercase mx-4">
                        {t("please")} {t("choose_a_year")}
                    </p>
                    <div tw="flex flex-col">
                        <button
                            tw="bg-map-white text-left mt-2 py-3 px-5"
                            onClick={() =>
                                setIsYearDropdownOpen(!isYearDropdownOpen)
                            }
                        >
                            <div tw="flex flex-row relative justify-start">
                                <p tw="pl-5">{year}</p>
                                <img
                                    src={ArrowDown}
                                    alt="Selected"
                                    tw="absolute right-2 top-1.5 h-3 w-3"
                                />
                            </div>
                        </button>
                        <div tw="w-full">
                            {isYearDropdownOpen && <YearDropdown />}
                        </div>
                    </div>
                </div>

                <button
                    tw="bg-map-white py-2 my-4 w-full rounded-2xl"
                    onClick={() =>
                        setFullModal(closeClick(modalTypes.mobileFilters))
                    }
                >
                    {t("explore_more")}
                </button>
                <button
                    tw="text-white w-full text-right"
                    onClick={() => handleFilterClick("reset", NONEOPTION)}
                >
                    <p tw="mr-4">{t("reset")}</p>
                </button>
            </div>
        </>
    );
};

export default MobileFilter;
