import { t } from "i18next";
import InfoHeader from "components/Menu/InfoHeader";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Methodology = () => (
    <>
        <div tw="flex flex-col">
            <InfoHeader />
            <div tw="mt-36">
                <div tw="flex justify-center">
                    <div tw="flex flex-col w-6/12 sm:w-full sm:p-4 justify-start">
                        <strong>
                            <p tw="my-8 text-xxl font-berlingske">
                                {t("methodology.title")}
                            </p>
                        </strong>
                        <p tw="my-2">
                            <strong>{t("methodology.update_version")}:</strong>{" "}
                            {t("methodology.update_text")}
                        </p>
                        <div>
                            <p tw="my-6">
                                {t("methodology.text_1")}{" "}
                                <a
                                    href="https://www.nature.com/articles/s41599-018-0083-y"
                                    tw="text-orange-700"
                                >
                                    {t("methodology.nature")}
                                </a>
                                . {t("methodology.text_2")}
                            </p>
                            <p tw="my-6">
                                {t("methodology.for_questions")}
                                <span tw="whitespace-pre"> </span>
                                <a
                                    href="mailto:data@worlddata.io"
                                    tw="text-orange-700"
                                >
                                    {t("url")}
                                </a>
                            </p>
                            <p tw="my-6">{t("methodology.text_3")}</p>
                            <p tw="my-6">
                                <strong>
                                    {t("methodology.addressing_discrepancies")}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                                {t("methodology.text_4")}
                            </p>
                            <p tw="my-6">{t("methodology.text_5")}</p>
                            <p tw="my-6">
                                <strong>
                                    {t(
                                        "methodology.countries_with_missing_surveys"
                                    )}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                                {t("methodology.text_6")}
                            </p>
                            <p tw="my-6">
                                <strong>
                                    {t("methodology.other_disaggregations")}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                            </p>
                            <p tw="my-6">
                                <strong>
                                    {t(
                                        "methodology.rural_urban_poverty_estimates"
                                    )}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                                {t("methodology.text_7")}
                            </p>
                            <p tw="my-6">{t("methodology.text_8")} </p>
                            <p tw="my-6">
                                <strong>
                                    {t(
                                        "methodology.age_gender_poverty_estimates"
                                    )}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                                {t("methodology.text_9")}
                            </p>
                            <p tw="my-6">{t("methodology.text_10")} </p>
                            <p tw="my-6">{t("methodology.text_11")}</p>
                            <p tw="my-6">{t("methodology.text_12")}</p>
                            <p tw="my-6">
                                <strong>
                                    {t(
                                        "methodology.age_gender_estimates_with_missing_data"
                                    )}
                                    <span tw="whitespace-pre"> </span>
                                </strong>
                                {t("methodology.text_13")}{" "}
                            </p>
                            <p tw="my-6"> {t("methodology.text_14")}</p>
                            <p tw="my-6"> {t("methodology.text_15")}</p>
                            <p tw="my-6">{t("methodology.text_16")}</p>
                            <p tw="my-6">
                                <strong>
                                    {t("methodology.selected_countries")}
                                </strong>
                            </p>
                            <p tw="my-6">
                                <strong>
                                    {t(
                                        "methodology.democratic_republic_of_congo"
                                    )}
                                </strong>
                            </p>
                            <p tw="my-6"> {t("methodology.drc_1")}</p>
                            <p tw="my-6">{t("methodology.drc_2")}</p>
                            <p tw="my-6">{t("methodology.drc_3")}</p>
                            <p tw="my-6">{t("methodology.drc_4")}</p>
                            <p tw="my-6">{t("methodology.data_is_sourced")}</p>
                            <p tw="my-6">
                                <strong>{t("methodology.nigeria")}</strong>
                            </p>
                            <p tw="my-6">{t("methodology.n_1")}</p>
                            <p tw="my-6">{t("methodology.n_2")}</p>
                            <p tw="my-6">{t("methodology.n_3")}</p>
                            <p tw="my-6">{t("methodology.n_4")}</p>
                            <p tw="my-6">
                                <strong>{t("methodology.india")}</strong>
                            </p>
                            <p tw="my-6">{t("methodology.i_1")}</p>
                            <p tw="my-6">{t("methodology.i_2")}</p>
                            <p tw="my-6">{t("methodology.i_3")}</p>
                            <p tw="my-6">{t("methodology.i_4")}</p>
                            <p tw="my-6">{t("methodology.i_5")}</p>
                            <p tw="my-6">{t("methodology.i_6")}</p>
                            <p tw="my-6">{t("methodology.i_7")}</p>
                            <p tw="my-6">
                                <strong>{t("methodology.indonesia")}</strong>
                            </p>
                            <p tw="my-6">{t("methodology.indo_1")}</p>
                            <p tw="my-6">{t("methodology.indo_2")}</p>
                            <p tw="my-6">{t("methodology.indo_3")}</p>
                            <p tw="my-6">
                                <strong>{t("methodology.venezuela")}</strong>
                            </p>
                            <p tw="my-6">{t("methodology.v_1")}</p>
                            <p tw="my-6">
                                <strong>{t("methodology.ukraine")}</strong>
                            </p>
                            <p tw="my-6">{t("methodology.u_1")}</p>
                            <p tw="my-6">{t("methodology.u_2")}</p>
                            <p tw="my-6">{t("methodology.u_3")}</p>
                            <p tw="my-6">
                                <strong>
                                    {t("methodology.north_korea_and_syria")}
                                </strong>
                            </p>
                            <p tw="my-6">
                                {t("methodology.to_be_updated_soon")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Methodology;
