import { useWorldPoverty } from "context/worldPoverty";
import Map from "assets/images/map.svg";
import Explore from "assets/images/explore.svg";
import { MapSectionRef } from "interfaces/main-ref.interface";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MobileFilters = ({ mapSectionRef }: MapSectionRef) => {
    const { setFullModal } = useWorldPoverty();
    return (
        <div tw="hidden sm:flex sm:flex-row sm:items-center sm:justify-center">
            <div tw="flex flex-row w-full mt-20 font-semiBold justify-center items-center uppercase">
                <button
                    tw="font-semiBold uppercase"
                    onClick={() =>
                        setFullModal({
                            name: "fullModal",
                            value: true,
                            section: "mobile_filters"
                        })
                    }
                >
                    <div tw="flex flex-row justify-center">
                        <div tw="bg-map-red py-1 px-4 mx-4 rounded-full flex flex-row items-center text-white tracking-normal font-workSans text-lg">
                            <img src={Explore} alt="Explore" tw="p-1" />
                            <p tw="font-bold capitalize pl-1">{t("filter")}</p>
                        </div>
                    </div>
                </button>
                <button
                    onClick={() =>
                        mapSectionRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        })
                    }
                    tw="flex flex-row"
                >
                    <div tw="flex flex-row justify-center">
                        <div tw="bg-map-red py-1 px-4 mx-4 rounded-full flex flex-row items-center text-white tracking-normal font-workSans text-lg">
                            <img src={Map} alt="Map" tw="p-1" />
                            <p tw="font-bold capitalize pl-1">{t("map")}</p>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default MobileFilters;
