import { t } from "i18next";
import InfoHeader from "components/Menu/InfoHeader";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const About = () => (
    <>
        <div tw="flex flex-col">
            <InfoHeader />
            <div tw="mt-36">
                <div tw="flex justify-center">
                    <div tw="flex flex-col w-6/12 sm:w-[100%] sm:mx-4 justify-start">
                        <p tw="my-8 text-xxl font-berlingske">
                            {t("about.title")}
                        </p>
                        <p tw="my-2 uppercase tracking-widest">
                            {t("about.subtitle")}
                        </p>
                        <div>
                            <p tw="my-6">{t("about.text_1")}</p>
                            <p tw="my-6">
                                {t("about.text_2")}{" "}
                                <a
                                    href="https://www.nature.com/articles/s41599-018-0083-y"
                                    tw="text-orange-700"
                                >
                                    {t("about.nature")}
                                </a>
                            </p>
                            <p tw="my-6">{t("about.text_3")}</p>
                            <p tw="my-6">
                                {t("about.for_more_information")}{" "}
                                <a
                                    href="https:/worlddata.io/"
                                    tw="text-orange-700"
                                >
                                    {t("url")}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default About;
