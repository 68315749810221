import { useState, useEffect } from "react";
import { default as ReactSelect } from "react-select";
import { useWorldPoverty } from "context/worldPoverty";
import { DropdownInfo } from "interfaces/options.interface";
import { SelectInfo } from "interfaces/select.interface";
import { hasSubnational } from "utils/Countries";
import { continentOptions } from "utils/Continents";
import { NONEOPTION, ALLOPTION } from "utils/Groups";
import { MobileStyling, DesktopStyling } from "utils/Styling";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import tw, { theme as twTheme } from "twin.macro";
import { MapSectionRef } from "interfaces/main-ref.interface";
import { swapMapFilters } from "utils/Map";

const Select = ({ mapSectionRef }: MapSectionRef) => {
    const {
        continent,
        continentData,
        country,
        countryData,
        county,
        countyData,
        main_query_data,
        mapView,
        setActiveFilters
    } = useWorldPoverty();
    const [isMobile, setIsMobile] = useState<boolean>(
        window.matchMedia(`(max-width: ${twTheme`screens.sm.max`})`).matches
    );
    const [currentDisplay, setCurrentDisplay] = useState<SelectInfo>({
        display: "All Countries",
        options: [],
        selected: null
    });

    function handleWindowSizeChange() {
        setIsMobile(
            window.matchMedia(`(max-width: ${twTheme`screens.sm.max`})`).matches
        );
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (continent === ALLOPTION) {
            setCurrentDisplay({
                display: "All Continents",
                options: continentOptions,
                selected: null
            });
        } else if (county === ALLOPTION && countryData) {
            setCurrentDisplay({
                display: "All Regions",
                options: [
                    {
                        id: ALLOPTION,
                        properties: { name: countryData.properties.name }
                    }
                ],
                selected: null
            });

            if (main_query_data && countryData) {
                setCurrentDisplay((previous) => ({
                    ...previous,
                    options: [
                        ...previous.options,
                        ...(countryData.countyValues!.sort(
                            (countryA, countryB) =>
                                countryA.properties.name.localeCompare(
                                    countryB.properties.name
                                )
                        ) as DropdownInfo[])
                    ]
                }));
            }
        } else if (countyData && continent === NONEOPTION) {
            setCurrentDisplay((previous) => ({
                ...previous,
                display: countyData.properties.name,
                selected: countyData
            }));
        } else if (
            country !== NONEOPTION &&
            continent === NONEOPTION &&
            main_query_data
        ) {
            setCurrentDisplay({
                display: "All Countries",
                options: [
                    {
                        id: ALLOPTION,
                        properties: { name: "All Countries" }
                    }
                ],
                selected: null
            });

            if (main_query_data && main_query_data.countryValues.length > 0) {
                setCurrentDisplay((previous) => ({
                    ...previous,
                    options: [
                        ...previous.options,
                        ...(main_query_data.countryValues
                            .filter(
                                (country) =>
                                    country.properties &&
                                    country.properties.name
                            )
                            .sort((countryA, countryB) =>
                                countryA.properties.name.localeCompare(
                                    countryB.properties.name
                                )
                            ) as DropdownInfo[])
                    ]
                }));
            }

            if (countryData) {
                setCurrentDisplay((previous) => ({
                    ...previous,
                    display: countryData.properties.name,
                    selected: countryData as DropdownInfo
                }));
            }
        } else if (continentData && country === NONEOPTION) {
            setCurrentDisplay((previous) => ({
                ...previous,
                display: continentData.properties.name,
                selected: continentData
            }));
        }
    }, [
        county,
        countyData,
        country,
        countryData,
        continent,
        continentData,
        main_query_data
    ]);

    const onChange = (option: DropdownInfo) => {
        setActiveFilters({
            name:
                continent === NONEOPTION
                    ? county !== NONEOPTION
                        ? "county"
                        : "country"
                    : "continent",
            value: option.id
        });
        setCurrentDisplay((previous) => ({
            ...previous,
            selected: option
        }));

        if (option.id !== ALLOPTION) {
            mapSectionRef.current &&
                mapSectionRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
        }
    };

    return (
        <div tw="z-[100] flex flex-col pl-4 pr-24 items-start xl:pr-4 lg:pr-4 md:pr-4 sm:m-0 sm:p-0 sm:flex-row sm:items-center">
            <p tw="sm:hidden block text-xs text-brown tracking-[3px] uppercase lg:text-[0.5rem] md:text-[0.5rem]">
                {t("search")}
            </p>
            <ReactSelect
                isDisabled={!main_query_data}
                isSearchable={!isMobile}
                tw="w-48 sm:w-full sm:font-workSansBold my-2 sm:my-0 border-b-[1px] border-black z-[105]lg:w-36 lg:text-[0.8rem]"
                styles={isMobile ? MobileStyling : DesktopStyling}
                theme={(theme) => ({
                    ...theme,
                    border: 0
                })}
                options={currentDisplay.options}
                getOptionLabel={(option) => option.properties.name}
                onChange={(option) => onChange(option as DropdownInfo)}
                placeholder={currentDisplay.display}
                value={currentDisplay.selected}
            />
            <div tw="sm:hidden absolute bottom-2 flex flex-row w-full max-w-[80%] lg:text-[0.8rem] md:text-[0.8rem] md:bottom-4">
                <button
                    tw="border border-black rounded-full px-4 py-1 capitalize"
                    onClick={() => {
                        setActiveFilters({
                            name: "country",
                            value:
                                continent === NONEOPTION
                                    ? NONEOPTION
                                    : ALLOPTION
                        });
                        setActiveFilters({
                            name: "continent",
                            value:
                                continent === NONEOPTION
                                    ? ALLOPTION
                                    : NONEOPTION
                        });
                        setActiveFilters({
                            name: "county",
                            value: NONEOPTION
                        });
                    }}
                    css={[
                        tw`text-white font-bold bg-black`,
                        continent === NONEOPTION &&
                            tw`text-black bg-white font-regular`
                    ]}
                >
                    {t("continents")}
                </button>
                <button
                    tw="border border-black rounded-full px-4 py-1 mx-2 capitalize invisible"
                    onClick={() => {
                        setActiveFilters({
                            name: "mapView",
                            value: swapMapFilters(mapView)
                        });
                        setActiveFilters({
                            name: "country",
                            value: country
                        });
                        setActiveFilters({
                            name: "county",
                            value:
                                county === NONEOPTION ? ALLOPTION : NONEOPTION
                        });
                    }}
                    css={[
                        hasSubnational.includes(country) && tw`visible`,
                        county !== NONEOPTION
                            ? tw`text-white font-bold bg-black`
                            : tw`text-black bg-white font-regular`
                    ]}
                >
                    {t("subnational")}
                </button>
            </div>
        </div>
    );
};

export default Select;
