import { useState } from "react";
import { t } from "i18next";
import ShareImg from "assets/images/share.svg";
import { socials } from "utils/Socials";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Share = () => {
    const [isShare, setShare] = useState(false);

    const handleShare = () => setShare(!isShare);

    return (
        <div tw="fixed -bottom-2 right-0 mr-12 flex flex-col items-center">
            <p tw="text-black uppercase rotate-90 tracking-[2px]">
                {t("share")}
            </p>
            <button
                tw="border border-white bg-map-red h-10 w-10 mx-8 mt-8 mb-4 rounded-full flex flex-row justify-center"
                onClick={handleShare}
            >
                <img src={ShareImg} alt="Share" tw="self-center scale-[1.6]" />
            </button>
            <div
                tw="absolute -top-16 h-28 w-28 flex flex-col items-center justify-end transition ease-in-out"
                css={[
                    tw`duration-300 translate-y-1 opacity-0`,
                    isShare && tw`duration-300 -translate-y-1 opacity-100 `
                ]}
            >
                {socials.slice(0, 3).map((s) => (
                    <button
                        tw="bg-white rounded-full m-2 p-[5px] shadow z-[100000]"
                        disabled={!isShare}
                        onClick={() => window.open(s.href, "_blank")}
                        css={{
                            transition: "visibility 1s",
                            visibility: isShare ? "visible" : "hidden"
                        }}
                        key={s.name}
                    >
                        <img src={s.img} alt={s.name} tw="h-10 w-10 p-2" />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Share;
