import { forwardRef, useMemo } from "react";
import { ScrollDown } from "components/Hero/ScrollDown";
import Select from "components/Filters/Select";
import { RadialWheel } from "components/Hero/RadialWheel";
import { MapSectionRef } from "interfaces/main-ref.interface";
import { InfoBoxes } from "components/Hero/InfoBoxes";
import { PercentInPoverty } from "components/Hero/PercentInPoverty";
import HeroMobileFilters from "components/Hero/HeroMobileFilters";
import { MainTicker } from "components/Hero/MainTicker";
import { PersonContainer } from "components/Hero/PersonContainer";
import useHeroTimer from "hooks/useHeroTimer";

/** @jsxImportSource @emotion/react */
import "twin.macro";
import { CountryNamesTicker } from "components/Hero/CountryNamesTicker";
import { useWorldPoverty } from "context/worldPoverty";

const HeroSection = forwardRef<HTMLDivElement | null, MapSectionRef>(
  ({ mapSectionRef }: MapSectionRef, ref) => {
    const {
      heroSectionData,
      timerQueue,
      dequeue,
      countryEscaping,
      countryIntoPov,
    } = useHeroTimer();

    const { main_query_data } = useWorldPoverty();

    const radialWheel = useMemo(
      () =>
        main_query_data &&
        main_query_data.worldData.finalData && (
          <RadialWheel
            headcount={main_query_data.worldData.finalData.headcount}
            totalPopulation={
              main_query_data.worldData.finalData!.totalPopulation
            }
          />
        ),
      [main_query_data]
    );
    return (
      <section ref={ref}>
        <div tw="flex flex-col h-screen max-h-[40rem] sm:max-h-[100%] mt-[6rem] sm:h-full text-black tracking-[2px] select-none">
          <div tw="flex flex-row justify-start h-[28rem] lg:scale-75 sm:scale-100 sm:mt-24 sm:flex-col sm:h-[38rem]">
            <div tw="relative top-[1rem] lg:top-0 left-[15vw] lg:left-[3vw] sm:flex sm:flex-col sm:left-0 sm:justify-self-start sm:h-full">
              {radialWheel}
              {main_query_data && (
                <>
                  <PersonContainer timerQueue={timerQueue} dequeue={dequeue} />
                  <PercentInPoverty
                    percentInPoverty={
                      (heroSectionData.headcount /
                        heroSectionData.totalPopulation) *
                      100
                    }
                  />
                </>
              )}
              <MainTicker
                headcount={heroSectionData.headcount}
                offTrack={heroSectionData.offTrack}
              />
            </div>
            <div tw="hidden sm:flex sm:flex-row sm:w-screen sm:justify-center sm:relative">
              <HeroMobileFilters mapSectionRef={mapSectionRef} />
            </div>
          </div>
          <CountryNamesTicker
            countryEscaping={countryEscaping}
            countryIntoPov={countryIntoPov}
          />
          {main_query_data &&
              <div tw="relative w-[40rem] left-[40%] bottom-[6%] lg:left-[30%] md:w-screen md:left-0">
                  <InfoBoxes heroSectionData={heroSectionData} />
              </div>}
            <div tw="relative bottom-[3.5rem] flex justify-center">
                <ScrollDown />
            </div>
        </div>
        <div tw="hidden sm:block">
          <Select mapSectionRef={mapSectionRef} />
        </div>
      </section>
    );
  }
);

export default HeroSection;
