export const modalTypes = {
    mobileFilters: "mobile_filters",
    mobileLenses: "mobile_lenses",
    explained_target_escape: "explained_target_escape",
    subscribe: "subscribe",
    explained_current_escape: "explained_current_escape",
    explained_year_end: "explained_year_end",
    explained_offtrack: "explained_offtrack"
};

export const closeClick = (section: string) => {
    return {
        name: "fullModal",
        value: false,
        section: section
    };
};
