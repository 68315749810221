import { t } from "i18next";
import { Link } from "react-router-dom";
import closeImg from "assets/images/close.svg";
import logo from "assets/images/logo.svg";
import icon_twitter from "assets/images/icon-twitter.png";
import icon_linkedin from "assets/images/icon-linkedin.png";
import icon_facebook from "assets/images/icon-facebook.png";
import icon_web from "assets/images/icon-web.png";
import { MenuCloseProps } from "interfaces/menu-props.interface";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Menu = ({ closeMenuCallback }: MenuCloseProps) => {
    return (
        <>
            <div tw="fixed inset-0 z-[999] bg-black sm:w-screen sm:h-full sm:top-0 sm:left-0 sm:bottom-0">
                <button tw="absolute top-6 right-8">
                    <img
                        src={closeImg}
                        alt="Menu"
                        tw="h-6 w-6"
                        onClick={closeMenuCallback}
                    />
                </button>
                <div tw="flex flex-row w-[80%] my-5 ml-32 align-bottom items-end md:ml-4 sm:ml-1">
                    <img
                        src={logo}
                        alt="Menu"
                        tw="h-10 w-10 mr-4 align-bottom"
                    />

                    <p tw="flex flex-row tracking-widest font-thin text-white align-bottom mt-2 font-workSans uppercase">
                        {" "}
                        {t("title")}
                    </p>
                </div>
                <div tw="flex flex-row sm:flex-col my-32 mx-16 text-white mt-20 md:mx-4 sm:mx-2">
                    <div tw="flex flex-col ml-16 sm:ml-2 pr-14">
                        <div tw="mt-7">
                            <p tw="font-workSans uppercase tracking-widest">
                                {t("about_wdl")}
                            </p>
                            <p tw="text-xxl font-bold my-4 font-berlingske">
                                {t("make_everyone_count")}
                            </p>
                            <p>{t("about_wdl_text")}</p>
                            <div tw="inline-block">
                                <p tw="text-orange-700 text-[1.1em] inline-block border-b-white border-b-2 my-4">
                                    <Link to={`/about`}>
                                        {t("find_out_more")}
                                    </Link>
                                </p>
                                <div tw="hidden sm:block">
                                    <div tw="flex flex-row justify-between text-white">
                                        <div tw="flex justify-center items-center w-9 h-9 bg-white rounded-full m-2">
                                            <a href="https://twitter.com/worlddatalab">
                                                <img
                                                    src={icon_twitter}
                                                    alt="Twitter"
                                                    tw="h-6 w-6"
                                                />
                                            </a>
                                        </div>
                                        <div tw="flex justify-center items-center w-9 h-9 bg-white rounded-full m-2">
                                            <a href="https://www.facebook.com/worlddatalab">
                                                <img
                                                    src={icon_facebook}
                                                    alt="Facebook"
                                                    tw="h-6 w-6 justify-center"
                                                />
                                            </a>
                                        </div>
                                        <div tw="flex justify-center items-center w-9 h-9 bg-white rounded-full m-2">
                                            <a href="https://www.linkedin.com/company/world-data-lab/">
                                                <img
                                                    src={icon_linkedin}
                                                    alt="LinkedIn"
                                                    tw="h-6 w-6"
                                                />
                                            </a>
                                        </div>
                                        <div tw="flex justify-center items-center w-9 h-9 bg-white rounded-full m-2">
                                            <a href="https://worlddata.io/">
                                                <img
                                                    src={icon_web}
                                                    alt="Web"
                                                    tw="h-6 w-6"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div tw="my-16 sm:border-white">
                                    <p tw="uppercase">
                                        <Link to={`methodology`}>
                                            {t("methodology.title")}
                                        </Link>
                                    </p>
                                    <p tw="uppercase">
                                        <a href="https://worlddata.io/blog/?category=world-poverty">
                                            {t("blog")}
                                        </a>
                                    </p>
                                    <p tw="uppercase hidden sm:block">
                                        <a href="https://worlddata.io/press-media">
                                            {t("press")}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw="flex flex-col pl-14 mr-20 border-l-white border-l-2 sm:hidden">
                        <div tw="mt-7">
                            <p tw="font-workSans uppercase tracking-widest">
                                {t("get_involved")}
                            </p>
                            <p tw="text-xxl font-bold my-4 font-berlingske">
                                {t("help_fund_expansion")}
                            </p>
                            <p>{t("help_us_text")}</p>
                            <div tw="inline-block">
                                <button
                                    className="hs-cta-trigger-button hs-cta-trigger-button-75725267674"
                                    tw="text-orange-700 text-[1.1rem] inline-block border-b-white border-b-2 my-4"
                                >
                                    <p>{t("get_in_touch")}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div tw="flex flex-row text-white ml-32 sm:hidden">
                    <p tw="mr-8">
                        <a href="https://www.linkedin.com/company/world-data-lab/">
                            {t("socials.linked_in")}
                        </a>
                    </p>
                    <p tw="mr-8">
                        <a href="https://twitter.com/worlddatalab">
                            {t("socials.twitter")}
                        </a>
                    </p>
                    <p tw="mr-8">
                        <a href="https://worlddata.io/press-media">
                            {t("press")}
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Menu;
