import { useWorldPoverty } from "context/worldPoverty";
import { t } from "i18next";
import { FeatureShape } from "interfaces/feature-shape.interface";
import { CountryCardLong, CountryCardRegular, CharLimit } from "utils/Styling";
import {
    povertyThresholds,
    dailySpendingValues,
    mapOptions,
    NONEOPTION
} from "utils/Groups";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import NumbersStringify from "utils/NumbersStringify";
import { rateTextFormatting } from "utils/Map";

const CountryCard = ({ originalEvent, target }: FeatureShape) => {
    const { main_query_data, countryData, county, mapView, daily_spending } =
        useWorldPoverty();
    const activeStyling = () => {
        if (!target.feature.finalData) {
            return [tw`bg-white text-white`];
        }

        return {
            backgroundColor: target.options.fillColor,
            color: target.options.fillColor
        };
    };

    const rateText = () => {
        if (!target.feature.finalData) {
            return t("no_data_available");
        }

        const thresh =
            povertyThresholds[
                dailySpendingValues.findIndex((item) => item === daily_spending)
            ];

        const denominator =
            county === NONEOPTION
                ? main_query_data!.worldData.finalData!.headcount
                : countryData!.finalData!.headcount;

        return rateTextFormatting(target, denominator, thresh, mapView);
    };

    return (
        <div
            css={[
                tw`z-[2000] flex flex-col m-4 p-8 pr-16 bg-black fixed text-white`,
                {
                    left: originalEvent?.clientX,
                    top: originalEvent?.clientY
                }
            ]}
        >
            <p tw="tracking-[2px] uppercase">
                {county === NONEOPTION ? t("country_card") : t("region_card")}
            </p>

            <div tw="flex flex-row items-center w-full">
                {target.feature.flag && (
                    <img
                        src={target.feature.flag.file_url}
                        alt="Flag"
                        tw="w-10 h-8 mx-2"
                    />
                )}
                <p
                    tw="font-berlingske capitalize"
                    css={
                        target.feature.properties.name.length > CharLimit
                            ? CountryCardLong
                            : CountryCardRegular
                    }
                >
                    {target.feature.properties.name}
                </p>
            </div>

            <p css={activeStyling} tw="text-[24px] font-bold bg-black">
                {target.feature.finalData &&
                    target.feature.finalData.stableTrack !== 0 &&
                    NumbersStringify(target.feature.finalData.headcount)}
            </p>
            <p
                tw="text-[18px]"
                css={mapView === mapOptions[0] && tw`capitalize`}
            >
                {rateText()}
            </p>
            <span
                tw="absolute h-12 w-12 rounded-full top-4 right-4"
                css={activeStyling}
            />
        </div>
    );
};

export default CountryCard;
