import { FilterGroups } from "interfaces/filter-props.interface";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

export const dailySpendingValues = [2.15, 3.65, 6.85];
export const povertyThresholds = [
    "extreme_poverty",
    "poverty",
    "highly_vulnerable_conditions"
];

export const ageGroups = [
    {
        name: "all ages",
        group: {
            from: 0,
            to: null
        }
    },
    {
        name: "0-15",
        group: {
            from: 0,
            to: 15
        }
    },
    {
        name: "15-30",
        group: {
            from: 15,
            to: 30
        }
    },
    {
        name: "30-45",
        group: {
            from: 30,
            to: 45
        }
    },
    {
        name: "45-65",
        group: {
            from: 45,
            to: 65
        }
    },
    {
        name: "65+",
        group: {
            from: 65,
            to: null
        }
    }
];

export const customAges: number[] = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75
];

export const filterGroups: FilterGroups[] = [
    {
        type: "geographical_filters",
        name: "region",
        filters: ["rural", "urban"]
    },
    {
        type: "demographic_filters",
        name: "ageGender",
        filters: ["females", "males", ageGroups[0].name]
    }
];

export const stableTracks = [
    {
        type: "rising",
        value: "poverty_is_rising",
        color: tw`bg-map-red text-white`
    },
    {
        type: "off_track",
        value: "off_track_for_sdg_target",
        color: tw`bg-map-yellow text-white`
    },
    {
        type: "on_track",
        value: "on_track_for_sdg_target",
        color: tw`bg-map-green text-white`
    },
    {
        type: "no_poverty",
        value: "poverty_below_three_percent",
        color: tw`bg-map-gray text-black`
    },
    {
        type: "no_data",
        value: "no_data",
        color: tw`bg-white text-black border border-gray-400`
    }
];

export const mapOptions = [
    "on_off_track_to_meet_sdg1",
    "poverty_relative_to_the_world",
    "poverty_relative_to_the_country"
];

export const countyMapOptions = [
    "on_off_track_to_meet_sdg1",
    "county_poverty_relative_to_the_country",
    "poverty_relative_to_the_region"
];

export const endYear = 2031;

export const yearOptions = (): number[] => {
    const yearRange = {
        start: 2016,
        end: endYear
    };
    const yearsList: number[] = [];
    for (var i = yearRange.start; i <= yearRange.end; i++) {
        yearsList.push(i);
    }
    return yearsList;
};

export const NONEOPTION = "none";
export const ALLOPTION = "all";

export const heroReducerOptions = {
    into: "INTOPOV",
    escape: "ESCAPEPOV",
    offtrack: "OFFTRACKINCREMENT",
    set: "SET"
};
