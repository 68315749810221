import { useState, useEffect } from "react";
import { t } from "i18next";
import { SplashQueueItem } from "interfaces/splashscreen.interface";
import Logo from "assets/images/logo.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Splash = () => {
    const [isMounted, setIsMounted] = useState(true);
    const [fadeImage, setFadeImage] = useState(false);
    const [title, setTitle] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [splashQueue, setSplashQueue] = useState<SplashQueueItem[]>([]);

    useEffect(() => {
        setSplashQueue([
            {
                id: 0,
                wait: 1200,
                item: () => setFadeImage(true)
            },
            {
                id: 1,
                wait: 600,
                item: () => setTitle(true)
            },
            {
                id: 3,
                wait: 2400,
                item: () => setFadeOut(true)
            },
            {
                id: 4,
                wait: 0,
                item: () => setIsMounted(false)
            }
        ]);
    }, []);

    useEffect(() => {
        if (splashQueue.length > 0) {
            const splashTimeout = setTimeout(() => {
                splashQueue[0].item();
                setSplashQueue((queue) =>
                    queue.filter((el) => el.id !== splashQueue[0].id)
                );
            }, splashQueue[0].wait);
            return () => {
                clearTimeout(splashTimeout);
            };
        }
    }, [splashQueue]);

    return isMounted ? (
        <section
            tw="fixed top-0 left-0 z-[1000] w-full h-full bg-white transition-opacity opacity-100 duration-[1200ms]"
            css={[fadeOut && tw`opacity-0`]}
        >
            <div
                tw="absolute w-full h-[15%] p-8 flex flex-row sm:flex-col items-center opacity-0 translate-y-2 transition duration-[1200ms]"
                css={[title && tw`opacity-100 translate-y-0`]}
            >
                <img
                    src={Logo}
                    alt="Logo"
                    tw="h-[100%] mr-8 sm:mr-0 sm:mb-2 sm:h-[50%]"
                />
                <p tw="mx-4 uppercase tracking-[2px] sm:tracking-[1px]">
                    {t("title")}
                </p>
                <p tw="mx-4">{t("by")}</p>
                <p tw="mx-4 uppercase tracking-[2px] sm:tracking-[1px]">
                    {t("world_data_lab")}
                </p>
            </div>
            <div tw="relative flex flex-col h-full justify-center p-8">
                <div tw="absolute z-[1001] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <div
                        tw="h-[400px] sm:h-[100px] w-[400px] sm:w-[100px] border-[48px] sm:border-[12px] border-black rounded-full flex flex-row items-center justify-center transition ease-out duration-[1600ms] opacity-100 scale-[1.5]"
                        css={[fadeImage && tw`opacity-30 scale-100`]}
                    >
                        <img src={Logo} alt="Logo Icon" tw="h-[60%] w-[60%]" />
                    </div>
                </div>
            </div>
        </section>
    ) : null;
};

export default Splash;
