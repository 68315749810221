import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Close from "assets/images/close.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const SubscribeForm = () => {
    const { setFullModal } = useWorldPoverty();

    const handleClick = () =>
        setFullModal({
            name: "fullModal",
            value: false,
            section: "subscribe"
        });

    return (
        <div tw="absolute flex flex-col items-center justify-around h-full w-[50%] bg-white opacity-100 px-16 py-8">
            <button
                tw="absolute top-8 right-8 h-8 w-8"
                onClick={() => handleClick()}
            >
                <img src={Close} alt="Close" tw="invert" />
            </button>
            <p tw="text-xl font-bold font-berlingske">
                {t("thank_you_for_your_interest")}
            </p>
            <div tw="flex flex-col divide-y-2 divide-black ">
                <div tw="mb-12">
                    <p tw="text-center">{t("we_are_working_on_expanding")}</p>
                </div>
                <div>
                    <p tw="pt-12 mb-4">{t("placeholder.name")}*</p>
                </div>
                <div>
                    <p tw="pt-12 mb-4">{t("placeholder.organization")}*</p>
                </div>
                <div>
                    <p tw="pt-12 mb-4">{t("placeholder.email")}*</p>
                </div>
                <div tw="pt-12 flex flex-row justify-center">
                    <button tw="bg-map-red rounded-lg font-bold text-white py-2 px-12 capitalize shadow">
                        {t("submit")}
                    </button>
                </div>
            </div>
            <p tw="text-[0.5em]">{t("legal_text")}</p>
        </div>
    );
};

export default SubscribeForm;
