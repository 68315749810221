import { useState, useEffect } from "react";
import { t } from "i18next";
import { useWorldPoverty } from "context/worldPoverty";
import Select from "components/Filters/Select";
import Filter from "components/Filters/Filter";
import { ageGroups, dailySpendingValues, filterGroups } from "utils/Groups";
import { tooltipTime } from "utils/Timing";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { MapSectionRef } from "interfaces/main-ref.interface";

const Filters = ({ mapSectionRef }: MapSectionRef) => {
    const { county, setActiveFilters } = useWorldPoverty();
    const [showAgeGenderInfo, setShowAgeGenderInfo] = useState(false);
    const [showRegionInfo, setShowRegionInfo] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAgeGenderInfo(false);
            setShowRegionInfo(false);
        }, tooltipTime);

        return () => {
            clearTimeout(timer);
        };
    }, [showAgeGenderInfo, showRegionInfo]);

    return (
        <section tw="flex flex-row w-[80%]">
            <div tw="flex flex-row w-full h-full">
                <div tw="flex flex-row divide-x divide-gray-300">
                    <Select mapSectionRef={mapSectionRef} />
                    {filterGroups.map((f) => (
                        <Filter
                            filterInfo={f}
                            key={f.type}
                            showAgeGenderInfo={showAgeGenderInfo}
                            setShowAgeGenderInfo={setShowAgeGenderInfo}
                            showRegionInfo={showRegionInfo}
                            setShowRegionInfo={setShowRegionInfo}
                        />
                    ))}
                </div>
                <button
                    tw="relative self-center bg-black text-white font-bold rounded-full h-8 w-min px-8 mt-4 lg:px-4 lg:text-[0.7rem] md:mt-8"
                    onClick={() => {
                        setActiveFilters({
                            name: "region",
                            value: "overall"
                        });
                        setActiveFilters({
                            name: "ageGender",
                            value: {
                                age: ageGroups[0],
                                gender: "overall"
                            }
                        });
                        setActiveFilters({
                            name: "daily_spending",
                            value: dailySpendingValues[0]
                        });
                        setShowAgeGenderInfo(false);
                        setShowRegionInfo(false);
                    }}
                    disabled={county !== "none"}
                >
                    {t("reset")}
                </button>
            </div>
        </section>
    );
};

export default Filters;
